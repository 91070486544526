import { CARD_LIMIT } from "./Data";
// URL
export const URL_GET_SCHOLARSHIP = `/scholarship-forms?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_SCHOLARSHIP = `/scholarship-forms/%(id)s`;
export const URL_UPDATE_STATUS_SCHOLARSHIP = "/scholarship-forms/%(id)s";
export const URL_DOWNLOAD_PARTICIPANT =
  "/scholarship-forms/%(id)s/download-participant";
export const URL_GENERATE_REPORT = "/scholarship-forms/reports?%(params)s";

// TYPE
export const types = {
  GET_SCHOLARSHIP_LOADING: "GET_SCHOLARSHIP_LOADING",
  GET_SCHOLARSHIP_SUCCESS: "GET_SCHOLARSHIP_SUCCESS",
  GET_SCHOLARSHIP_FAILED: "GET_SCHOLARSHIP_FAILED",

  GET_DETAIL_SCHOLARSHIP_LOADING: "GET_DETAIL_SCHOLARSHIP_LOADING",
  GET_DETAIL_SCHOLARSHIP_SUCCESS: "GET_DETAIL_SCHOLARSHIP_SUCCESS",
  GET_DETAIL_SCHOLARSHIP_FAILED: "GET_DETAIL_SCHOLARSHIP_FAILED",

  UPDATE_STATUS_SCHOLARSHIP_LOADING: "GET_STATUS_SCHOLARSHIP_LOADING",
  UPDATE_STATUS_SCHOLARSHIP_SUCCESS: "GET_STATUS_SCHOLARSHIP_SUCCESS",
  UPDATE_STATUS_SCHOLARSHIP_FAILED: "GET_STATUS_SCHOLARSHIP_FAILED",
};
