// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_OUTLET_MUTASI = `/reports/mutations/outlet-points?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_OUTLET_MUTASI_DETAIL = `/reports/mutations/outlet-points/%(id)s/details?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_SALES_MUTASI = `/reports/mutations/sellers?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_SALES_MUTASI_DETAIL = `/reports/mutations/sellers/%(id)s/details?_limit=${CARD_LIMIT}%(params)s`;
export const URL_REPORTING_MUTASI_OUTLET = `/reports/mutations/outlet-points/reports?_limit=10%(params)s`;
export const URL_REPORTING_MUTASI_SALES = `/reports/mutations/sellers/reports?_limit=10%(params)s`;
export const URL_REPORTING_MUTASI_DETAIL_OUTLET = `/reports/mutations/outlet-points/%(id)s/details/reports?_limit=10%(params)s`;
export const URL_REPORTING_MUTASI_DETAIL_SALES = `/reports/mutations/sellers/%(id)s/details/reports?_limit=10%(params)s`;

// Types
export const types = {
    GET_OUTLET_MUTASI_POINT_LOADING: 'GET_OUTLET_MUTASI_POINT_LOADING',
    GET_OUTLET_MUTASI_POINT_SUCCESS: 'GET_OUTLET_MUTASI_POINT_SUCCESS',
    GET_OUTLET_MUTASI_POINT_FAILED: 'GET_OUTLET_MUTASI_POINT_FAILED',

    GET_OUTLET_MUTASI_POINT_DETAIL_LOADING:
        'GET_OUTLET_MUTASI_POINT_DETAIL_LOADING',
    GET_OUTLET_MUTASI_POINT_DETAIL_SUCCESS:
        'GET_OUTLET_MUTASI_POINT_DETAIL_SUCCESS',
    GET_OUTLET_MUTASI_POINT_DETAIL_FAILED:
        'GET_OUTLET_MUTASI_POINT_DETAIL_FAILED',

    GET_SALES_MUTASI_POINT_LOADING: 'GET_SALES_MUTASI_POINT_LOADING',
    GET_SALES_MUTASI_POINT_SUCCESS: 'GET_SALES_MUTASI_POINT_SUCCESS',
    GET_SALES_MUTASI_POINT_FAILED: 'GET_SALES_MUTASI_POINT_FAILED',

    GET_SALES_MUTASI_POINT_DETAIL_LOADING:
        'GET_SALES_MUTASI_POINT_DETAIL_LOADING',
    GET_SALES_MUTASI_POINT_DETAIL_SUCCESS:
        'GET_SALES_MUTASI_POINT_DETAIL_SUCCESS',
    GET_SALES_MUTASI_POINT_DETAIL_FAILED:
        'GET_SALES_MUTASI_POINT_DETAIL_FAILED',
};
