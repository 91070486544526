import { types } from 'constants/MasterData';

const initialState = {
    masterData: {
        listCategory: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
            params: '',
        },
        onCreate: {
            loading: false,
            error: '',
        },
        onDelete: {
            loading: false,
            error: '',
        },
        onUpdate: {
            loading: false,
            error: '',
        },
        detail: {
            loading: false,
            error: '',
            data: {},
        },
        listBrand: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
            params: '',
        },
        onCreateBrand: {
            loading: false,
            error: '',
        },
        onDeleteBrand: {
            loading: false,
            error: '',
        },
        onUpdateBrand: {
            loading: false,
            error: '',
        },
        detailBrand: {
            loading: false,
            error: '',
            data: {},
        },
        listSubCat: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
            params: '',
        },
        onCreateSubCat: {
            loading: false,
            error: '',
        },
        onDeleteSubCat: {
            loading: false,
            error: '',
        },
        onUpdateSubCat: {
            loading: false,
            error: '',
        },
        detailSubCat: {
            loading: false,
            error: '',
            data: {},
        },
    },
};

const masterData = (state = initialState.masterData, action) => {
    switch (action.type) {
        case types.GET_CATEGORY_LOADING:
            return {
                ...state,
                listCategory: {
                    ...state.listCategory,
                    loading: true,
                },
            };
        case types.GET_CATEGORY_SUCCESS:
            return {
                ...state,
                listCategory: {
                    ...state.listCategory,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listCategory.page,
                    params: action.params || '',
                },
            };
        case types.GET_CATEGORY_FAILED:
            return {
                ...state,
                listCategory: {
                    ...state.listCategory,
                    loading: false,
                    error: action.message,
                },
            };

        case types.CREATE_CATEGORY_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.CREATE_CATEGORY_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.CREATE_CATEGORY_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_CATEGORY_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_CATEGORY_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };

        case types.SHOW_CATEGORY_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.SHOW_CATEGORY_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_CATEGORY_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_CATEGORY_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_CATEGORY_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_CATEGORY_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_BRAND_LOADING:
            return {
                ...state,
                listBrand: {
                    ...state.listBrand,
                    loading: true,
                },
            };
        case types.GET_BRAND_SUCCESS:
            return {
                ...state,
                listBrand: {
                    ...state.listBrand,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listBrand.page,
                    params: action.params || '',
                },
            };
        case types.GET_BRAND_FAILED:
            return {
                ...state,
                listBrand: {
                    ...state.listBrand,
                    loading: false,
                    error: action.message,
                },
            };

        case types.CREATE_BRAND_LOADING:
            return {
                ...state,
                onCreateBrand: {
                    ...state.onCreateBrand,
                    loading: true,
                },
            };
        case types.CREATE_BRAND_SUCCESS:
            return {
                ...state,
                onCreateBrand: {
                    ...state.onCreateBrand,
                    loading: false,
                },
            };
        case types.CREATE_BRAND_FAILED:
            return {
                ...state,
                onCreateBrand: {
                    ...state.onCreateBrand,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_BRAND_LOADING:
            return {
                ...state,
                onDeleteBrand: {
                    ...state.onDeleteBrand,
                    loading: true,
                },
            };
        case types.DELETE_BRAND_SUCCESS:
            return {
                ...state,
                onDeleteBrand: {
                    ...state.onDeleteBrand,
                    loading: false,
                },
            };
        case types.DELETE_BRAND_FAILED:
            return {
                ...state,
                onDeleteBrand: {
                    ...state.onDeleteBrand,
                    loading: false,
                    error: action.message,
                },
            };

        case types.SHOW_BRAND_LOADING:
            return {
                ...state,
                detailBrand: {
                    ...state.detailBrand,
                    loading: true,
                },
            };
        case types.SHOW_BRAND_SUCCESS:
            return {
                ...state,
                detailBrand: {
                    ...state.detailBrand,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_BRAND_FAILED:
            return {
                ...state,
                detailBrand: {
                    ...state.detailBrand,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_BRAND_LOADING:
            return {
                ...state,
                onUpdateBrand: {
                    ...state.onUpdateBrand,
                    loading: true,
                },
            };
        case types.UPDATE_BRAND_SUCCESS:
            return {
                ...state,
                onUpdateBrand: {
                    ...state.onUpdateBrand,
                    loading: false,
                },
            };
        case types.UPDATE_BRAND_FAILED:
            return {
                ...state,
                onUpdateBrand: {
                    ...state.onUpdateBrand,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_SUBCAT_LOADING:
            return {
                ...state,
                listSubCat: {
                    ...state.listSubCat,
                    loading: true,
                },
            };
        case types.GET_SUBCAT_SUCCESS:
            return {
                ...state,
                listSubCat: {
                    ...state.listSubCat,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listSubCat.page,
                    params: action.params || '',
                },
            };
        case types.GET_SUBCAT_FAILED:
            return {
                ...state,
                listSubCat: {
                    ...state.listSubCat,
                    loading: false,
                    error: action.message,
                },
            };

        case types.CREATE_SUBCAT_LOADING:
            return {
                ...state,
                onCreateSubCat: {
                    ...state.onCreateSubCat,
                    loading: true,
                },
            };
        case types.CREATE_SUBCAT_SUCCESS:
            return {
                ...state,
                onCreateSubCat: {
                    ...state.onCreateSubCat,
                    loading: false,
                },
            };
        case types.CREATE_SUBCAT_FAILED:
            return {
                ...state,
                onCreateSubCat: {
                    ...state.onCreateSubCat,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_SUBCAT_LOADING:
            return {
                ...state,
                onDeleteSubCat: {
                    ...state.onDeleteSubCat,
                    loading: true,
                },
            };
        case types.DELETE_SUBCAT_SUCCESS:
            return {
                ...state,
                onDeleteSubCat: {
                    ...state.onDeleteSubCat,
                    loading: false,
                },
            };
        case types.DELETE_SUBCAT_FAILED:
            return {
                ...state,
                onDeleteSubCat: {
                    ...state.onDeleteSubCat,
                    loading: false,
                    error: action.message,
                },
            };

        case types.SHOW_SUBCAT_LOADING:
            return {
                ...state,
                detailSubCat: {
                    ...state.detailSubCat,
                    loading: true,
                },
            };
        case types.SHOW_SUBCAT_SUCCESS:
            return {
                ...state,
                detailSubCat: {
                    ...state.detailSubCat,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_SUBCAT_FAILED:
            return {
                ...state,
                detailSubCat: {
                    ...state.detailSubCat,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_SUBCAT_LOADING:
            return {
                ...state,
                onUpdateSubCat: {
                    ...state.onUpdateSubCat,
                    loading: true,
                },
            };
        case types.UPDATE_SUBCAT_SUCCESS:
            return {
                ...state,
                onUpdateSubCat: {
                    ...state.onUpdateSubCat,
                    loading: false,
                },
            };
        case types.UPDATE_SUBCAT_FAILED:
            return {
                ...state,
                onUpdateSubCat: {
                    ...state.onUpdateSubCat,
                    loading: false,
                    error: action.message,
                },
            };
        default:
            return state;
    }
};

export default masterData;
