// URL
import { CARD_LIMIT } from "./Data";
export const URL_GET_PROMO = `/promos?_limit=${CARD_LIMIT}%(params)s`;
export const URL_PROMO = `/promos`;
export const URL_DETAIL_PROMO = `/promos/%(id)s`;
export const URL_GET_REGENCY = `/promos/%(id)s/promos-regencies?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_TERMS = `/promos/%(id)s/promo-terms`

// Types
export const types = {
   GET_PROMO_LOADING: "GET_PROMO_LOADING",
   GET_PROMO_SUCCESS: "GET_PROMO_SUCCESS",
   GET_PROMO_FAILED: "GET_PROMO_FAILED",

   CREATE_PROMO_LOADING: "CREATE_PROMO_LOADING",
   CREATE_PROMO_SUCCESS: "CREATE_PROMO_SUCCESS",
   CREATE_PROMO_FAILED: "CREATE_PROMO_FAILED",

   SHOW_PROMO_LOADING: "SHOW_PROMO_LOADING",
   SHOW_PROMO_SUCCESS: "SHOW_PROMO_SUCCESS",
   SHOW_PROMO_FAILED: "SHOW_PROMO_FAILED",

   UPDATE_PROMO_LOADING: "UPDATE_PROMO_LOADING",
   UPDATE_PROMO_SUCCESS: "UPDATE_PROMO_SUCCESS",
   UPDATE_PROMO_FAILED: "UPDATE_PROMO_FAILED",

   DELETE_PROMO_LOADING: "DELETE_PROMO_LOADING",
   DELETE_PROMO_SUCCESS: "DELETE_PROMO_SUCCESS",
   DELETE_PROMO_FAILED: "DELETE_PROMO_FAILED",

   GET_REGENCY_PROMO_LOADING: "GET_REGENCY_PROMO_LOADING",
   GET_REGENCY_PROMO_SUCCESS: "GET_REGENCY_PROMO_SUCCESS",
   GET_REGENCY_PROMO_FAILED: "GET_REGENCY_PROMO_FAILED"
};
