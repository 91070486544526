import { CARD_LIMIT } from "./Data";

export const URL_GET_NC_CLUSTER = `/beautiful-numbers/clusters?_order=asc&_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_NC_BY_CID = `/beautiful-numbers?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_NC_TRX_BY_CID = `/beautiful-numbers/transaction-items?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_TRX_NC = `/beautiful-numbers/transaction-items/%(id)s`;
export const URL_BULK_CREATE_NC = '/beautiful-numbers/bulk-create'
export const URL_BULK_STATUS_NC = '/beautiful-numbers/bulk-update-sold'
export const URL_GET_NC_COUNTER = '/beautiful-numbers/counter?cluster_id=%(cluster_id)s'
export const URL_UPDATE_TRX_ITEM_NC = '/beautiful-numbers/transaction-items/%(trxId)s'


// Types
export const types = {
  GET_NC_CLUSTER_LOADING: "GET_NC_CLUSTER_LOADING",
  GET_NC_CLUSTER_SUCCESS: "GET_NC_CLUSTER_SUCCESS",
  GET_NC_CLUSTER_FAILED: "GET_NC_CLUSTER_FAILED",

  GET_NC_BY_CID_LOADING: "GET_NC_BY_CID_LOADING",
  GET_NC_BY_CID_SUCCESS: "GET_NC_BY_CID_SUCCESS",
  GET_NC_BY_CID_FAILED: "GET_NC_BY_CID_FAILED",

  GET_NC_TRX_BY_CID_LOADING: "GET_NC_TRX_BY_CID_LOADING",
  GET_NC_TRX_BY_CID_SUCCESS: "GET_NC_TRX_BY_CID_SUCCESS",
  GET_NC_TRX_BY_CID_FAILED: "GET_NC_TRX_BY_CID_FAILED",

  GET_DETAIL_TRX_NC_LOADING: "GET_DETAIL_TRX_NC_LOADING",
  GET_DETAIL_TRX_NC_SUCCESS: "GET_DETAIL_TRX_NC_SUCCESS",
  GET_DETAIL_TRX_NC_FAILED: "GET_DETAIL_TRX_NC_FAILED",

  BULK_CREATE_NC_LOADING: "BULK_CREATE_NC_LOADING",
  BULK_CREATE_NC_SUCCESS: "BULK_CREATE_NC_SUCCESS",
  BULK_CREATE_NC_FAILED: "BULK_CREATE_NC_FAILED",

  BULK_STATUS_NC_LOADING: "BULK_STATUS_NC_LOADING",
  BULK_STATUS_NC_SUCCESS: "BULK_STATUS_NC_SUCCESS",
  BULK_STATUS_NC_FAILED: "BULK_STATUS_NC_FAILED",

  GET_COUNTER_TRX_NC_LOADING: "GET_COUNTER_TRX_NC_LOADING",
  GET_COUNTER_TRX_NC_SUCCESS: "GET_COUNTER_TRX_NC_SUCCESS",
  GET_COUNTER_TRX_NC_FAILED: "GET_COUNTER_TRX_NC_FAILED",

  UPDATE_TRX_ITEM_NC_LOADING: "UPDATE_TRX_ITEM_NC_LOADING",
  UPDATE_TRX_ITEM_NC_SUCCESS: "UPDATE_TRX_ITEM_NC_SUCCESS",
  UPDATE_TRX_ITEM_NC_FAILED: "UPDATE_TRX_ITEM_NC_FAILED",

};
