// URL
import { CARD_LIMIT } from "./Data";
export const URL_GET_HADIAH = `/gifts?_limit=${CARD_LIMIT}%(params)s`;
export const URL_HADIAH = `/gifts`;
export const URL_DETAIL_HADIAH = `/gifts/%(id)s`;
export const URL_GET_REGENCY = `/gifts/%(id)s/gifts-regencies?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_REDEEM = `gift-exchanges/items?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_TERMS = `/gifts/%(id)s/gift-terms?_order=asc`;
export const URL_GET_LOTTERIES = `/gift-exchanges/lotteries?_limit=${CARD_LIMIT}%(params)s`;
export const URL_DELIVERY = `/gift-exchange-deliveries/%(id)s`;

// Types
export const types = {
  GET_HADIAH_LOADING: "GET_HADIAH_LOADING",
  GET_HADIAH_SUCCESS: "GET_HADIAH_SUCCESS",
  GET_HADIAH_FAILED: "GET_HADIAH_FAILED",

  DELIVERY_LOADING: "DELIVERY_LOADING",
  DELIVERY_SUCCESS: "DELIVERY_SUCCESS",
  DELIVERY_FAILED: "DELIVERY_FAILED",

  GET_REDEEM_LOADING: "GET_REDEEM_LOADING",
  GET_REDEEM_SUCCESS: "GET_REDEEM_SUCCESS",
  GET_REDEEM_FAILED: "GET_REDEEM_FAILED",

  SHOW_HADIAH_LOADING: "SHOW_HADIAH_LOADING",
  SHOW_HADIAH_SUCCESS: "SHOW_HADIAH_SUCCESS",
  SHOW_HADIAH_FAILED: "SHOW_HADIAH_FAILED",

  CREATE_HADIAH_LOADING: "CREATE_HADIAH_LOADING",
  CREATE_HADIAH_SUCCESS: "CREATE_HADIAH_SUCCESS",
  CREATE_HADIAH_FAILED: "CREATE_HADIAH_FAILED",

  DELETE_HADIAH_LOADING: "DELETE_HADIAH_LOADING",
  DELETE_HADIAH_SUCCESS: "DELETE_HADIAH_SUCCESS",
  DELETE_HADIAH_FAILED: "DELETE_HADIAH_FAILED",

  UPDATE_HADIAH_LOADING: "UPDATE_HADIAH_LOADING",
  UPDATE_HADIAH_SUCCESS: "UPDATE_HADIAH_SUCCESS",
  UPDATE_HADIAH_FAILED: "UPDATE_HADIAH_FAILED",

  GET_LOTTERIES_LOADING: "GET_LOTTERIES_LOADING",
  GET_LOTTERIES_SUCCESS: "GET_LOTTERIES_SUCCESS",
  GET_LOTTERIES_FAILED: "GET_LOTTERIES_FAILED",

  GET_REGENCY_HADIAH_LOADING: "GET_REGENCY_HADIAH_LOADING",
  GET_REGENCY_HADIAH_SUCCESS: "GET_REGENCY_HADIAH_SUCCESS",
  GET_REGENCY_HADIAH_FAILED: "GET_REGENCY_HADIAH_FAILED",
};
