import { CARD_LIMIT } from "./Data";

export const URL_GET_TRANSFER_SALDO = `/transfer-wallets?_limit=${CARD_LIMIT}%(params)s`;
export const URL_REPORT_TRANSFER_SALDO = "/transfer-wallets/reports?%(params)s";

export const types = {
  GET_TRANSFER_SALDO_LOADING: "GET_TRANSFER_SALDO_LOADING",
  GET_TRANSFER_SALDO_SUCCESS: "GET_TRANSFER_SALDO_SUCCESS",
  GET_TRANSFER_SALDO_FAILED: "GET_TRANSFER_SALDO_FAILED",
};
