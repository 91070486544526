import { types } from "constants/SupportTicket";

const initialState = {
   supportTicket: {
      list: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onCreate: {
         loading: false,
         error: ""
      },
      onUpdate: {
         loading: false,
         error: ""
      },
      detail: {
         loading: false,
         error: "",
         data: {}
      }
   }
};

const supportTicket = (state = initialState.supportTicket, action) => {
   switch (action.type) {
      case types.GET_SUPPORT_TICKET_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true
            }
         };
      case types.GET_SUPPORT_TICKET_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.list.page
            }
         };
      case types.GET_SUPPORT_TICKET_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_SUPPORT_TICKET_LOADING:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: true
            }
         };
      case types.CREATE_SUPPORT_TICKET_SUCCESS:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false
            }
         };
      case types.CREATE_SUPPORT_TICKET_FAILED:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
               error: action.message
            }
         };

      case types.UPDATE_SUPPORT_TICKET_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true
            }
         };
      case types.UPDATE_SUPPORT_TICKET_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false
            }
         };
      case types.UPDATE_SUPPORT_TICKET_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message
            }
         };

      case types.SHOW_SUPPORT_TICKET_LOADING:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: true
            }
         };
      case types.SHOW_SUPPORT_TICKET_SUCCESS:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               data: action.data
            }
         };
      case types.SHOW_SUPPORT_TICKET_FAILED:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               error: action.message,
               data: {}
            }
         };

      default:
         return state;
   }
};

export default supportTicket;
