import { types } from "constants/Transfer";

const initialState = {
  transfer: {
    list: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
    },
  },
};

const transfer = (state = initialState.transfer, action) => {
  switch (action.type) {
    case types.GET_TRANSFER_SALDO_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_TRANSFER_SALDO_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          page: action.page || state.list.page,
        },
      };
    case types.GET_TRANSFER_SALDO_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };
    default:
      return state;
  }
};

export default transfer;
