import { types } from 'constants/NomorCantik'

const initialState = {
    nc: {
        clusters: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        numbers: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        transactions: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        transaction: {
            loading: false,
            data: {},
            error: '',
        },
        counter: {
            loading: false,
            data: {},
            error: '',
        },
        onCreate: {
            loading: false,
            error: '',
        },
        onBulkStatus: {
            loading: false,
            error: '',
        },
        onUpdateTrxStatus: {
            loading: false,
            error: '',
        },
    },
}

const ncReducer = (state = initialState.nc, action) => {
    switch (action.type) {
        case types.GET_NC_CLUSTER_LOADING:
            return {
                ...state,
                clusters: {
                    ...state.clusters,
                    loading: true,
                },
            }
        case types.GET_NC_CLUSTER_SUCCESS:
            return {
                ...state,
                clusters: {
                    ...state.clusters,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.clusters?.page,
                },
            }
        case types.GET_NC_CLUSTER_FAILED:
            return {
                ...state,
                clusters: {
                    ...state.clusters,
                    loading: false,
                    error: action.message,
                },
            }
        case types.GET_NC_BY_CID_LOADING:
            return {
                ...state,
                numbers: {
                    ...state.numbers,
                    loading: true,
                },
            }
        case types.GET_NC_BY_CID_SUCCESS:
            return {
                ...state,
                numbers: {
                    ...state.numbers,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.numbers?.page,
                },
            }
        case types.GET_NC_BY_CID_FAILED:
            return {
                ...state,
                numbers: {
                    ...state.numbers,
                    loading: false,
                    error: action.message,
                },
            }
        case types.GET_NC_TRX_BY_CID_LOADING:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: true,
                },
            }
        case types.GET_NC_TRX_BY_CID_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.transactions?.page,
                },
            }
        case types.GET_NC_TRX_BY_CID_FAILED:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: false,
                    error: action.message,
                },
            }

        case types.GET_DETAIL_TRX_NC_LOADING:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    loading: true,
                },
            }
        case types.GET_DETAIL_TRX_NC_SUCCESS:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    data: action.data,
                    loading: false,
                },
            }
        case types.GET_DETAIL_TRX_NC_FAILED:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    loading: false,
                    error: action.message,
                },
            }
        case types.GET_COUNTER_TRX_NC_LOADING:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    loading: true,
                },
            }
        case types.GET_COUNTER_TRX_NC_SUCCESS:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    data: action.data,
                    loading: false,
                },
            }
        case types.GET_COUNTER_TRX_NC_FAILED:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    loading: false,
                    error: action.message,
                },
            }
        case types.BULK_CREATE_NC_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            }
        case types.BULK_CREATE_NC_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            }
        case types.BULK_CREATE_NC_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            }

        case types.BULK_STATUS_NC_LOADING:
            return {
                ...state,
                onBulkStatus: {
                    ...state.onBulkStatus,
                    loading: true,
                },
            }
        case types.BULK_STATUS_NC_SUCCESS:
            return {
                ...state,
                onBulkStatus: {
                    ...state.onBulkStatus,
                    loading: false,
                },
            }
        case types.BULK_STATUS_NC_FAILED:
            return {
                ...state,
                onBulkStatus: {
                    ...state.onBulkStatus,
                    loading: false,
                    error: action.message,
                },
            }

        case types.UPDATE_TRX_ITEM_NC_LOADING:
            return {
                ...state,
                onUpdateTrxStatus: {
                    ...state.onUpdateTrxStatus,
                    loading: true,
                },
            }
        case types.UPDATE_TRX_ITEM_NC_SUCCESS:
            return {
                ...state,
                onUpdateTrxStatus: {
                    ...state.onUpdateTrxStatus,
                    loading: false,
                },
            }
        case types.UPDATE_TRX_ITEM_NC_FAILED:
            return {
                ...state,
                onUpdateTrxStatus: {
                    ...state.onUpdateTrxStatus,
                    loading: false,
                    error: action.message,
                },
            }

        default:
            return state
    }
}

export default ncReducer
