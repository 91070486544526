// URL
import { CARD_LIMIT } from './Data';
export const URL_REGENCY = `/regencies?_limit=${CARD_LIMIT + 30}&%(params)s`;
export const URL_CLUSTER = `/clusters?_limit=${CARD_LIMIT + 30}%(params)s`;
export const URL_DISTRICT = `/districts?_limit=${CARD_LIMIT + 30}%(params)s`;
export const URL_SALES = `/sellers?_limit=${CARD_LIMIT + 30}%(params)s`;
export const URL_OUTLET = `/outlets?_limit=${CARD_LIMIT + 30}%(params)s`;
export const URL_PRODUCT_BRAND = `/product-categories/%(id)s/product-brands?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_PRODUCT_ALLBRAND = `/product-brands?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

export const URL_PRODUCT_CATEGORY = `product-categories?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_GROUPBY_CATEGORY = `/utility/initializers?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_PRODUCT_SUBCATEGORY = `/product-brands/%(id)s/product-subcategories?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_PRODUCT_ALLSUBCATEGORY = `/product-subcategories?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_PRODUCT_DENOM = `/product-subcategories/%(id)s/product-denoms?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

export const URL_PRODUCT_SUPPLIER = `/product-suppliers?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

export const URL_PRODUCT_ALLDENOM = `/product-denoms?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_ARTICLE_CATEGORY = `article-categories?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

export const URL_CREATE_DENOM = `/product-subcategories/%(id)s/product-denoms`;

export const URL_GET_ACTIVITY_ITEM = `/log/activities/item-types?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

export const URL_GET_LIST_WORK =
    '/scholarship-form-attributes?group_key=WORK%(params)s';
export const URL_GET_LIST_DEVICE_TYPE =
    '/scholarship-form-attributes?group_key=DEVICE_TYPE%(params)s';
export const URL_GET_LIST_PLAY_DURATION =
    '/scholarship-form-attributes?group_key=PLAY_DURATION%(params)s';
export const URL_GET_LIST_INFO_FROM =
    '/scholarship-form-attributes?group_key=INFO_FROM%(params)s';

export const URL_GET_MERCHANTS = `/merchants?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_GET_CATEGORY_PRODUCT_MERCHANT = `/tb-product-categories?_limit=${
    CARD_LIMIT + 30
}%(params)s`;
export const URL_GET_SUBCATEGORY_PRODUCT_MERCHANT = `/tb-product-categories/%(id)s/children?_limit=${
    CARD_LIMIT + 30
}%(params)s`;

// Types
export const types = {
    GET_REGENCY_LOADING: 'GET_REGENCY_LOADING',
    GET_REGENCY_SUCCESS: 'GET_REGENCY_SUCCESS',
    GET_REGENCY_FAILED: 'GET_REGENCY_FAILED',

    GET_DISTRICT_LOADING: 'GET_DISTRICT_LOADING',
    GET_DISTRICT_SUCCESS: 'GET_DISTRICT_SUCCESS',
    GET_DISTRICT_FAILED: 'GET_DISTRICT_FAILED',

    CREATE_DENOM_LOADING: 'CREATE_DENOM_LOADING',
    CREATE_DENOM_SUCCESS: 'CREATE_DENOM_SUCCESS',
    CREATE_DENOM_FAILED: 'CREATE_DENOM_FAILED',

    GET_OPTSALES_LOADING: 'GET_OPTSALES_LOADING',
    GET_OPTSALES_SUCCESS: 'GET_OPTSALES_SUCCESS',
    GET_OPTSALES_FAILED: 'GET_OPTSALES_FAILED',

    GET_OPTARTICLE_CATEGORY_LOADING: 'GET_OPTARTICLE_CATEGORY_LOADING',
    GET_OPTARTICLE_CATEGORY_SUCCESS: 'GET_OPTARTICLE_CATEGORY_SUCCESS',
    GET_OPTARTICLE_CATEGORY_FAILED: 'GET_OPTARTICLE_CATEGORY_FAILED',

    GET_OPTOUTLET_LOADING: 'GET_OPTOUTLET_LOADING',
    GET_OPTOUTLET_SUCCESS: 'GET_OPTOUTLET_SUCCESS',
    GET_OPTOUTLET_FAILED: 'GET_OPTOUTLET_FAILED',

    GET_OPTSUPPLIER_LOADING: 'GET_OPTSUPPLIER_LOADING',
    GET_OPTSUPPLIER_SUCCESS: 'GET_OPTSUPPLIER_SUCCESS',
    GET_OPTSUPPLIER_FAILED: 'GET_OPTSUPPLIER_FAILED',

    GET_OPTPRODUCT_BRAND_LOADING: 'GET_OPTPRODUCT_BRAND_LOADING',
    GET_OPTPRODUCT_BRAND_SUCCESS: 'GET_OPTPRODUCT_BRAND_SUCCESS',
    GET_OPTPRODUCT_BRAND_FAILED: 'GET_OPTPRODUCT_BRAND_FAILED',

    GET_OPTPRODUCT_CATEGORY_LOADING: 'GET_OPTPRODUCT_CATEGORY_LOADING',
    GET_OPTPRODUCT_CATEGORY_SUCCESS: 'GET_OPTPRODUCT_CATEGORY_SUCCESS',
    GET_OPTPRODUCT_CATEGORY_FAILED: 'GET_OPTPRODUCT_CATEGORY_FAILED',

    GET_GROUPBY_CATEGORY_LOADING: 'GET_GROUPBY_CATEGORY_LOADING',
    GET_GROUPBY_CATEGORY_SUCCESS: 'GET_GROUPBY_CATEGORY_SUCCESS',
    GET_GROUPBY_CATEGORY_FAILED: 'GET_GROUPBY_CATEGORY_FAILED',

    GET_OPTPRODUCT_SUBCATEGORY_LOADING: 'GET_OPTPRODUCT_SUBCATEGORY_LOADING',
    GET_OPTPRODUCT_SUBCATEGORY_SUCCESS: 'GET_OPTPRODUCT_SUBCATEGORY_SUCCESS',
    GET_OPTPRODUCT_SUBCATEGORY_FAILED: 'GET_OPTPRODUCT_SUBCATEGORY_FAILED',

    GET_OPTPRODUCT_DENOM_LOADING: 'GET_OPTPRODUCT_DENOM_LOADING',
    GET_OPTPRODUCT_DENOM_SUCCESS: 'GET_OPTPRODUCT_DENOM_SUCCESS',
    GET_OPTPRODUCT_DENOM_FAILED: 'GET_OPTPRODUCT_DENOM_FAILED',

    GET_OPTCLUSTER_LOADING: 'GET_OPTCLUSTER_LOADING',
    GET_OPTCLUSTER_SUCCESS: 'GET_OPTCLUSTER_SUCCESS',
    GET_OPTCLUSTER_FAILED: 'GET_OPTCLUSTER_FAILED',

    GET_OPTACTIVITY_ITEM_LOADING: 'GET_OPTACTIVITY_ITEM_LOADING',
    GET_OPTACTIVITY_ITEM_SUCCESS: 'GET_OPTACTIVITY_ITEM_SUCCESS',
    GET_OPTACTIVITY_ITEM_FAILED: 'GET_OPTACTIVITY_ITEM_FAILED',

    GET_LIST_WORK_LOADING: 'GET_LIST_WORK_LOADING',
    GET_LIST_WORK_SUCCESS: 'GET_LIST_WORK_SUCCESS',
    GET_LIST_WORK_FAILED: 'GET_LIST_WORK_FAILED',

    GET_LIST_DEVICE_TYPE_LOADING: 'GET_LIST_DEVICE_TYPE_LOADING',
    GET_LIST_DEVICE_TYPE_SUCCESS: 'GET_LIST_DEVICE_TYPE_SUCCESS',
    GET_LIST_DEVICE_TYPE_FAILED: 'GET_LIST_DEVICE_TYPE_FAILED',

    GET_LIST_PLAY_DURATION_LOADING: 'GET_LIST_PLAY_DURATION_LOADING',
    GET_LIST_PLAY_DURATION_SUCCESS: 'GET_LIST_PLAY_DURATION_SUCCESS',
    GET_LIST_PLAY_DURATION_FAILED: 'GET_LIST_PLAY_DURATION_FAILED',

    GET_LIST_INFO_FROM_LOADING: 'GET_LIST_INFO_FROM_LOADING',
    GET_LIST_INFO_FROM_SUCCESS: 'GET_LIST_INFO_FROM_SUCCESS',
    GET_LIST_INFO_FROM_FAILED: 'GET_LIST_INFO_FROM_FAILED',

    GET_MERCHANT_LOADING: 'GET_MERCHANT_LOADING',
    GET_MERCHANT_SUCCESS: 'GET_MERCHANT_SUCCESS',
    GET_MERCHANT_FAILED: 'GET_MERCHANT_FAILED',

    GET_CATEGORY_PRODUCT_MERCHANT_LOADING:
        'GET_CATEGORY_PRODUCT_MERCHANT_LOADING',
    GET_CATEGORY_PRODUCT_MERCHANT_SUCCESS:
        'GET_CATEGORY_PRODUCT_MERCHANT_SUCCESS',
    GET_CATEGORY_PRODUCT_MERCHANT_FAILED:
        'GET_CATEGORY_PRODUCT_MERCHANT_FAILED',

    GET_SUBCATEGORY_PRODUCT_MERCHANT_LOADING:
        'GET_SUBCATEGORY_PRODUCT_MERCHANT_LOADING',
    GET_SUBCATEGORY_PRODUCT_MERCHANT_SUCCESS:
        'GET_SUBCATEGORY_PRODUCT_MERCHANT_SUCCESS',
    GET_SUBCATEGORY_PRODUCT_MERCHANT_FAILED:
        'GET_SUBCATEGORY_PRODUCT_MERCHANT_FAILED',
};
