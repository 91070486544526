// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_OUTLET = `/outlets?_limit=${CARD_LIMIT}%(params)s`;
export const URL_DETAIL_OUTLET = `/outlets/%(id)s`;
export const URL_TOOGLE_ACTIVE_OUTLET = `/outlets/%(id)s/status`;
export const URL_REPORTING_OUTLET = `/outlets/reports?&%(params)s`;

// Types
export const types = {
    GET_OUTLET_LOADING: 'GET_OUTLET_LOADING',
    GET_OUTLET_SUCCESS: 'GET_OUTLET_SUCCESS',
    GET_OUTLET_FAILED: 'GET_OUTLET_FAILED',

    SHOW_OUTLET_LOADING: 'SHOW_OUTLET_LOADING',
    SHOW_OUTLET_SUCCESS: 'SHOW_OUTLET_SUCCESS',
    SHOW_OUTLET_FAILED: 'SHOW_OUTLET_FAILED',

    DELETE_OUTLET_LOADING: 'DELETE_OUTLET_LOADING',
    DELETE_OUTLET_SUCCESS: 'DELETE_OUTLET_SUCCESS',
    DELETE_OUTLET_FAILED: 'DELETE_OUTLET_FAILED',

    UPDATE_OUTLET_LOADING: 'UPDATE_OUTLET_LOADING',
    UPDATE_OUTLET_SUCCESS: 'UPDATE_OUTLET_SUCCESS',
    UPDATE_OUTLET_FAILED: 'UPDATE_OUTLET_FAILED',
};
