import { types } from 'constants/Mutasi';

const initialState = {
    mutasi: {
        outlet: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        sales: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        detailOutlet: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        detailSales: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
    },
};

const mutasi = (state = initialState.mutasi, action) => {
    switch (action.type) {
        case types.GET_OUTLET_MUTASI_LOADING:
            return {
                ...state,
                outlet: {
                    ...state.outlet,
                    loading: true,
                },
            };
        case types.GET_OUTLET_MUTASI_SUCCESS:
            return {
                ...state,
                outlet: {
                    ...state.outlet,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.outlet?.page,
                },
            };
        case types.GET_OUTLET_MUTASI_FAILED:
            return {
                ...state,
                outlet: {
                    ...state.outlet,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_SALES_MUTASI_LOADING:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: true,
                },
            };
        case types.GET_SALES_MUTASI_SUCCESS:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.sales?.page,
                },
            };
        case types.GET_SALES_MUTASI_FAILED:
            return {
                ...state,
                sales: {
                    ...state.sales,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_OUTLET_MUTASI_DETAIL_LOADING:
            return {
                ...state,
                detailOutlet: {
                    ...state.detailOutlet,
                    loading: true,
                },
            };
        case types.GET_OUTLET_MUTASI_DETAIL_SUCCESS:
            return {
                ...state,
                detailOutlet: {
                    ...state.detailOutlet,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.detailOutlet?.page,
                },
            };
        case types.GET_OUTLET_MUTASI_DETAIL_FAILED:
            return {
                ...state,
                detailOutlet: {
                    ...state.detailOutlet,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_SALES_MUTASI_DETAIL_LOADING:
            return {
                ...state,
                detailSales: {
                    ...state.detailSales,
                    loading: true,
                },
            };
        case types.GET_SALES_MUTASI_DETAIL_SUCCESS:
            return {
                ...state,
                detailSales: {
                    ...state.detailSales,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.detailSales?.page,
                },
            };
        case types.GET_SALES_MUTASI_DETAIL_FAILED:
            return {
                ...state,
                detailSales: {
                    ...state.detailSales,
                    loading: false,
                    error: action.message,
                },
            };

        default:
            return state;
    }
};

export default mutasi;
