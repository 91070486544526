import { types } from 'constants/BosPoin';

const initialState = {
   outlet: {
      list: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      onUpdate: {
         loading: false,
         error: '',
      },
      onDelete: {
         loading: false,
         error: '',
      },
      detail: {
         loading: false,
         data: {},
         error: '',
      },
   },
};

const outlet = (state = initialState.outlet, action) => {
   switch (action.type) {
      case types.GET_BOSPOIN_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true,
            },
         };
      case types.GET_BOSPOIN_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state?.list?.page,
            },
         };
      case types.GET_BOSPOIN_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message,
            },
         };
      case types.GET_REFUND_BOSPOIN_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true,
            },
         };
      case types.GET_REFUND_BOSPOIN_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state?.list?.page,
            },
         };
      case types.GET_REFUND_BOSPOIN_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message,
            },
         };

      default:
         return state;
   }
};

export default outlet;
