// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_BOSVIDEO = `/bos-videos?_limit=${CARD_LIMIT}%(params)s`;
export const URL_BOS_VIDEO = `/bos-videos`;
export const URL_DETAIL_BOS_VIDEO = `/bos-videos/%(id)s`;
// Types
export const types = {
    GET_BOSVIDEO_LOADING: 'GET_BOSVIDEO_LOADING',
    GET_BOSVIDEO_SUCCESS: 'GET_BOSVIDEO_SUCCESS',
    GET_BOSVIDEO_FAILED: 'GET_BOSVIDEO_FAILED',

    CREATE_BOSVIDEO_LOADING: 'CREATE_BOSVIDEO_LOADING',
    CREATE_BOSVIDEO_SUCCESS: 'CREATE_BOSVIDEO_SUCCESS',
    CREATE_BOSVIDEO_FAILED: 'CREATE_BOSVIDEO_FAILED',

    DELETE_BOSVIDEO_LOADING: 'DELETE_BOSVIDEO_LOADING',
    DELETE_BOSVIDEO_SUCCESS: 'DELETE_BOSVIDEO_SUCCESS',
    DELETE_BOSVIDEO_FAILED: 'DELETE_BOSVIDEO_FAILED',

    GET_DETAIL_BOSVIDEO_LOADING: 'GET_DETAIL_BOSVIDEO_LOADING',
    GET_DETAIL_BOSVIDEO_SUCCESS: 'GET_DETAIL_BOSVIDEO_SUCCESS',
    GET_DETAIL_BOSVIDEO_FAILED: 'GET_DETAIL_BOSVIDEO_FAILED',

    UPDATE_BOSVIDEO_LOADING: ' UPDATE_BOSVIDEO_LOADING',
    UPDATE_BOSVIDEO_SUCCESS: ' UPDATE_BOSVIDEO_SUCCESS',
    UPDATE_BOSVIDEO_FAILED: ' UPDATE_BOSVIDEO_FAILED',
};
