import { types } from 'constants/ActivityLogs';

const initialState = {
    activityLogs: {
        list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
    },
};

const activityLogs = (state = initialState.activityLogs, action) => {
    switch (action.type) {
        case types.GET_ACTIVITY_LOGS_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_ACTIVITY_LOGS_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.list.page,
                },
            };
        case types.GET_ACTIVITY_LOGS_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };

        default:
            return state;
    }
};

export default activityLogs;
