import { types } from "constants/Wisgames";

const initialState = {
  wisgames: {
    list: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
    },
    detail: {
      loading: false,
      error: "",
      data: {},
    },
    onUpdate: {
      loading: false,
      error: "",
    },
  },
};

const wisgames = (state = initialState.wisgames, action) => {
  switch (action.type) {
    case types.GET_SCHOLARSHIP_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };

    case types.GET_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          page: action.page || state.list.page,
        },
      };

    case types.GET_SCHOLARSHIP_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_DETAIL_SCHOLARSHIP_LOADING:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: true,
        },
      };

    case types.GET_DETAIL_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          data: action.data,
        },
      };

    case types.GET_DETAIL_SCHOLARSHIP_FAILED:
      return {
        ...state,
        detail: {
          ...state.detail,
          loading: false,
          error: action.message,
        },
      };

    case types.UPDATE_STATUS_SCHOLARSHIP_LOADING:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: true,
        },
      };

    case types.UPDATE_STATUS_SCHOLARSHIP_SUCCESS:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
        },
      };

    case types.UPDATE_STATUS_SCHOLARSHIP_FAILED:
      return {
        ...state,
        onUpdate: {
          ...state.onUpdate,
          loading: false,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default wisgames;
