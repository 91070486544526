import { types } from 'constants/Outlet';

const initialState = {
    outlet: {
        list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        onUpdate: {
            loading: false,
            error: '',
        },
        onDelete: {
            loading: false,
            error: '',
        },
        detail: {
            loading: false,
            data: {},
            error: '',
        },
    },
};

const outlet = (state = initialState.outlet, action) => {
    switch (action.type) {
        case types.GET_OUTLET_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_OUTLET_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.list?.page,
                },
            };
        case types.GET_OUTLET_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };

        case types.DELETE_OUTLET_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_OUTLET_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_OUTLET_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_OUTLET_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_OUTLET_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_OUTLET_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.SHOW_OUTLET_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.SHOW_OUTLET_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_OUTLET_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };

        default:
            return state;
    }
};

export default outlet;
