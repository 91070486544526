// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_SALES = `/sellers?_limit=${CARD_LIMIT}%(params)s`;
export const URL_SALES = `/sellers`;
export const URL_SALES_DETAIL = `/sellers/%(id)s`;
export const URL_SUSPEND = `/sellers/%(id)s/suspend`;
export const URL_UPDATE_STATUS = `/sellers/%(id)s/status`;
export const URL_REPORTING_SALES = `/sellers/reports?%(params)s`;
export const URL_GET_DAYS = `/days?_order=asc`;
export const URL_GET_OPERATIONAL_HOUR = `/operational-hours?_limit=${CARD_LIMIT}%(params)s`;
export const URL_OPERATIONAL_HOUR = `/operational-hours/regencies`;

// Types
export const types = {
   GET_SALES_LOADING: 'GET_SALES_LOADING',
   GET_SALES_SUCCESS: 'GET_SALES_SUCCESS',
   GET_SALES_FAILED: 'GET_SALES_FAILED',

   GET_SALES_DETAIL_LOADING: 'GET_SALES_DETAIL_LOADING',
   GET_SALES_DETAIL_SUCCESS: 'GET_SALES_DETAIL_SUCCESS',
   GET_SALES_DETAIL_FAILED: 'GET_SALES_DETAIL_FAILED',

   GET_DAYS_LOADING: 'GET_DAYS_LOADING',
   GET_DAYS_SUCCESS: 'GET_DAYS_SUCCESS',
   GET_DAYS_FAILED: 'GET_DAYS_FAILED',

   GET_OPERATIONAL_HOUR_LOADING: 'GET_OPERATIONAL_HOUR_LOADING',
   GET_OPERATIONAL_HOUR_SUCCESS: 'GET_OPERATIONAL_HOUR_SUCCESS',
   GET_OPERATIONAL_HOUR_FAILED: 'GET_OPERATIONAL_HOUR_FAILED',

   SET_OPERATIONAL_HOUR_LOADING: 'SET_OPERATIONAL_HOUR_LOADING',
   SET_OPERATIONAL_HOUR_SUCCESS: 'SET_OPERATIONAL_HOUR_SUCCESS',
   SET_OPERATIONAL_HOUR_FAILED: 'SET_OPERATIONAL_HOUR_FAILED',

   CREATE_SALES_LOADING: 'CREATE_SALES_LOADING',
   CREATE_SALES_SUCCESS: 'CREATE_SALES_SUCCESS',
   CREATE_SALES_FAILED: 'CREATE_SALES_FAILED',

   UPDATE_SALES_LOADING: 'UPDATE_SALES_LOADING',
   UPDATE_SALES_SUCCESS: 'UPDATE_SALES_SUCCESS',
   UPDATE_SALES_FAILED: 'UPDATE_SALES_FAILED',

   SUSPEND_SALES_LOADING: 'SUSPEND_SALES_LOADING',
   SUSPEND_SALES_SUCCESS: 'SUSPEND_SALES_SUCCESS',
   SUSPEND_SALES_FAILED: 'SUSPEND_SALES_FAILED',

   SCHEDULE_SALES_LOADING: 'SCHEDULE_SALES_LOADING',
   SCHEDULE_SALES_SUCCESS: 'SCHEDULE_SALES_SUCCESS',
   SCHEDULE_SALES_FAILED: 'SCHEDULE_SALES_FAILED',

   UPDATE_STATUS_SALES_LOADING: 'UPDATE_STATUS_SALES_LOADING',
   UPDATE_STATUS_SALES_SUCCESS: 'UPDATE_STATUS_SALES_SUCCESS',
   UPDATE_STATUS_SALES_FAILED: 'UPDATE_STATUS_SALES_FAILED',
};
