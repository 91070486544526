import { types } from "constants/Promo";

const initialState = {
   promo: {
      list: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onCreate: {
         loading: false,
         error: ""
      },
      onDelete: {
         loading: false,
         error: ""
      },
      onUpdate: {
         loading: false,
         error: ""
      },
      detail: {
         loading: false,
         error: "",
         data: {}
      },
      regency: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      }
   }
};

const promo = (state = initialState.promo, action) => {
   switch (action.type) {
      case types.GET_PROMO_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true
            }
         };
      case types.GET_PROMO_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.list.page
            }
         };
      case types.GET_PROMO_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_PROMO_LOADING:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: true
            }
         };
      case types.CREATE_PROMO_SUCCESS:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false
            }
         };
      case types.CREATE_PROMO_FAILED:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
               error: action.message
            }
         };

      case types.DELETE_PROMO_LOADING:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: true
            }
         };
      case types.DELETE_PROMO_SUCCESS:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false
            }
         };
      case types.DELETE_PROMO_FAILED:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false,
               error: action.message
            }
         };

      case types.UPDATE_PROMO_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true
            }
         };
      case types.UPDATE_PROMO_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false
            }
         };
      case types.UPDATE_PROMO_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message
            }
         };

      case types.SHOW_PROMO_LOADING:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: true
            }
         };
      case types.SHOW_PROMO_SUCCESS:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               data: action.data
            }
         };
      case types.SHOW_PROMO_FAILED:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               error: action.message,
               data: {}
            }
         };

      case types.GET_REGENCY_PROMO_LOADING:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: true
            }
         };
      case types.GET_REGENCY_PROMO_SUCCESS:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page
            }
         };
      case types.GET_REGENCY_PROMO_FAILED:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               error: action.message,
               data: []
            }
         };
      default:
         return state;
   }
};

export default promo;
