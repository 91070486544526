import { types } from "constants/Transaction";

const initialState = {
  transaction: {
    list: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
      totalPending: 0,
    },
    modem: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
      totalPending: 0,
    },
    perdana: {
      loading: false,
      error: "",
      data: [],
      page: 1,
      totalPage: null,
      totalData: 0,
      totalPending: 0,
    },
    onChangeStatus: {
      loading: false,
      error: "",
    },
  },
};

const transaction = (state = initialState.transaction, action) => {
  switch (action.type) {
    case types.GET_TRANSACTION_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_TRANSACTION_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          totalPending: action.totalPending
            ? parseInt(action.totalPending)
            : state.list?.totalPending,
          page: action.page || state.list.page,
        },
      };
    case types.GET_TRANSACTION_FAILED:
      return {
        ...state,
        list: {
          ...state.list,
          loading: false,
          error: action.message,
        },
      };

    case types.SET_TRANSACTION_STATUS_LOADING:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: true,
        },
      };
    case types.SET_TRANSACTION_STATUS_SUCCESS:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
        },
      };
    case types.SET_TRANSACTION_STATUS_FAILED:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
          error: action.message,
        },
      };

    case types.GET_TRANSACTION_MODEM_LOADING:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
      };
    case types.GET_TRANSACTION_MODEM_SUCCESS:
      return {
        ...state,
        modem: {
          ...state.modem,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          totalPending: action.totalPending
            ? parseInt(action.totalPending)
            : state.modem?.totalPending,
          page: action.page || state.modem.page,
        },
      };
    case types.GET_TRANSACTION_MODEM_FAILED:
      return {
        ...state,
        modem: {
          ...state.modem,
          loading: false,
          error: action.message,
        },
      };

    case types.SET_TRANSACTION_MODEM_STATUS_LOADING:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: true,
        },
      };
    case types.SET_TRANSACTION_MODEM_STATUS_SUCCESS:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
        },
      };
    case types.SET_TRANSACTION_MODEM_STATUS_FAILED:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
          error: action.message,
        },
      };
    case types.GET_TRANSACTION_PERDANA_LOADING:
      return {
        ...state,
        perdana: {
          ...state.perdana,
          loading: true,
        },
      };
    case types.GET_TRANSACTION_PERDANA_SUCCESS:
      return {
        ...state,
        perdana: {
          ...state.perdana,
          loading: false,
          data: action.data,
          totalPage: parseInt(action.totalPage),
          totalData: parseInt(action.totalData),
          totalPending: action.totalPending
            ? parseInt(action.totalPending)
            : state.perdana?.totalPending,
          page: action.page || state.perdana.page,
        },
      };
    case types.GET_TRANSACTION_PERDANA_FAILED:
      return {
        ...state,
        perdana: {
          ...state.perdana,
          loading: false,
          error: action.message,
        },
      };

    case types.SET_TRANSACTION_PERDANA_STATUS_LOADING:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: true,
        },
      };
    case types.SET_TRANSACTION_PERDANA_STATUS_SUCCESS:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
        },
      };
    case types.SET_TRANSACTION_PERDANA_STATUS_FAILED:
      return {
        ...state,
        onChangeStatus: {
          ...state.onChangeStatus,
          loading: false,
          error: action.message,
        },
      };

    default:
      return state;
  }
};

export default transaction;
