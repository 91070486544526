import { types } from "constants/TopUp";

const initialState = {
   topup: {
      sales: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onCreateSales: {
         loading: false,
         error: ""
      },
      outlet: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
         totalPermintaan: 0
      },
      viaHelpDesk: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
         totalPermintaan: 0
      },
      onDeclineOutlet: {
         loading: false,
         error: ""
      },
      onAcceptOutlet: {
         loading: false,
         error: ""
      },
      saldo: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
         totalPermintaan: 0
      },
      onCreateSaldo: {
         loading: false,
         error: ""
      },
      onDeclineSaldo: {
         loading: false,
         error: ""
      },
      onAcceptSaldo: {
         loading: false,
         error: ""
      },
      onCollected: {
         loading: false,
         error: ""
      }
   }
};

const topup = (state = initialState.topup, action) => {
   switch (action.type) {
      case types.GET_TOPUP_SALES_LOADING:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: true
            }
         };
      case types.GET_TOPUP_SALES_SUCCESS:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.sales.page
            }
         };
      case types.GET_TOPUP_SALES_FAILED:
         return {
            ...state,
            sales: {
               ...state.sales,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_TOPUP_SALES_LOADING:
         return {
            ...state,
            onCreateSales: {
               ...state.onCreateSales,
               loading: true
            }
         };
      case types.CREATE_TOPUP_SALES_SUCCESS:
         return {
            ...state,
            onCreateSales: {
               ...state.onCreateSales,
               loading: false
            }
         };
      case types.CREATE_TOPUP_SALES_FAILED:
         return {
            ...state,
            onCreateSales: {
               ...state.onCreateSales,
               loading: false,
               error: action.message
            }
         };

      case types.GET_TOPUP_OUTLET_LOADING:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: true
            }
         };
      case types.GET_TOPUP_OUTLET_SUCCESS:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               totalPermintaan:
                  action.status === "PENDING"
                     ? parseInt(action.totalData)
                     : state.outlet.totalPermintaan,
               page: action.page || state.outlet.page
            }
         };
      case types.GET_TOPUP_OUTLET_FAILED:
         return {
            ...state,
            outlet: {
               ...state.outlet,
               loading: false,
               error: action.message
            }
         };

      case types.GET_TOPUP_VIA_HELPDESK_LOADING:
         return {
            ...state,
            viaHelpDesk: {
               ...state.viaHelpDesk,
               loading: true
            }
         };
      case types.GET_TOPUP_VIA_HELPDESK_SUCCESS:
         return {
            ...state,
            viaHelpDesk: {
               ...state.viaHelpDesk,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               totalPermintaan:
                  action.status === "PENDING"
                     ? parseInt(action.totalData)
                     : state.viaHelpDesk.totalPermintaan,
               page: action.page|| state.viaHelpDesk.page
            }
         };
      case types.GET_TOPUP_VIA_HELPDESK_FAILED:
         return {
            ...state,
            viaHelpDesk: {
               ...state.viaHelpDesk,
               loading: false,
               error: action.message
            }
         };

      case types.DECLINE_TOPUP_OUTLET_LOADING:
         return {
            ...state,
            onDeclineOutlet: {
               ...state.onDeclineOutlet,
               loading: true
            }
         };
      case types.DECLINE_TOPUP_OUTLET_SUCCESS:
         return {
            ...state,
            onDeclineOutlet: {
               ...state.onDeclineOutlet,
               loading: false
            }
         };
      case types.DECLINE_TOPUP_OUTLET_FAILED:
         return {
            ...state,
            onDeclineOutlet: {
               ...state.onDeclineOutlet,
               loading: false,
               error: action.message
            }
         };

      case types.ACCEPT_TOPUP_OUTLET_LOADING:
         return {
            ...state,
            onAcceptOutlet: {
               ...state.onAcceptOutlet,
               loading: true
            }
         };
      case types.ACCEPT_TOPUP_OUTLET_SUCCESS:
         return {
            ...state,
            onAcceptOutlet: {
               ...state.onAcceptOutlet,
               loading: false
            }
         };
      case types.ACCEPT_TOPUP_OUTLET_FAILED:
         return {
            ...state,
            onAcceptOutlet: {
               ...state.onAcceptOutlet,
               loading: false,
               error: action.message
            }
         };

      case types.GET_TOPUP_SALDO_LOADING:
         return {
            ...state,
            saldo: {
               ...state.saldo,
               loading: true
            }
         };
      case types.GET_TOPUP_SALDO_SUCCESS:
         return {
            ...state,
            saldo: {
               ...state.saldo,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               totalPermintaan:
                  action.status !== "ACCEPT"
                     ? parseInt(action.totalData)
                     : state.saldo.totalPermintaan,
               page: action.page || state.saldo.page
            }
         };
      case types.GET_TOPUP_SALDO_FAILED:
         return {
            ...state,
            saldo: {
               ...state.saldo,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_TOPUP_SALDO_LOADING:
         return {
            ...state,
            onCreateSaldo: {
               ...state.onCreateSaldo,
               loading: true
            }
         };
      case types.CREATE_TOPUP_SALDO_SUCCESS:
         return {
            ...state,
            onCreateSaldo: {
               ...state.onCreateSaldo,
               loading: false
            }
         };
      case types.CREATE_TOPUP_SALDO_FAILED:
         return {
            ...state,
            onCreateSaldo: {
               ...state.onCreateSaldo,
               loading: false,
               error: action.message
            }
         };

      case types.ACCEPT_TOPUP_SALDO_LOADING:
         return {
            ...state,
            onAcceptSaldo: {
               ...state.onAcceptSaldo,
               loading: true
            }
         };
      case types.ACCEPT_TOPUP_SALDO_SUCCESS:
         return {
            ...state,
            onAcceptSaldo: {
               ...state.onAcceptSaldo,
               loading: false
            }
         };
      case types.ACCEPT_TOPUP_SALDO_FAILED:
         return {
            ...state,
            onAcceptSaldo: {
               ...state.onAcceptSaldo,
               loading: false,
               error: action.message
            }
         };

      case types.DECLINE_TOPUP_SALDO_LOADING:
         return {
            ...state,
            onDeclineSaldo: {
               ...state.onDeclineSaldo,
               loading: true
            }
         };
      case types.DECLINE_TOPUP_SALDO_SUCCESS:
         return {
            ...state,
            onDeclineSaldo: {
               ...state.onDeclineSaldo,
               loading: false
            }
         };
      case types.DECLINE_TOPUP_SALDO_FAILED:
         return {
            ...state,
            onDeclineSaldo: {
               ...state.onDeclineSaldo,
               loading: false,
               error: action.message
            }
         };

      case types.SET_COLLECTED_SALES_LOADING:
         return {
            ...state,
            onCollected: {
               ...state.onCollected,
               loading: true
            }
         };
      case types.SET_COLLECTED_SALES_SUCCESS:
         return {
            ...state,
            onCollected: {
               ...state.onCollected,
               loading: false
            }
         };
      case types.SET_COLLECTED_SALES_FAILED:
         return {
            ...state,
            onCollected: {
               ...state.onCollected,
               loading: false,
               error: action.message
            }
         };

      default:
         return state;
   }
};

export default topup;
