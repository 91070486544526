// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_BOSPOIN = `/izzi-loyalties?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_REFUND_BOSPOIN = `/izzi-webhooks?_limit=${CARD_LIMIT}%(params)s`;

// Types
export const types = {
   GET_BOSPOIN_LOADING: 'GET_BOSPOIN_LOADING',
   GET_BOSPOIN_SUCCESS: 'GET_BOSPOIN_SUCCESS',
   GET_BOSPOIN_FAILED: 'GET_BOSPOIN_FAILED',

   GET_REFUND_BOSPOIN_LOADING: 'GET_REFUND_BOSPOIN_LOADING',
   GET_REFUND_BOSPOIN_SUCCESS: 'GET_REFUND_BOSPOIN_SUCCESS',
   GET_REFUND_BOSPOIN_FAILED: 'GET_REFUND_BOSPOIN_FAILED',
};
