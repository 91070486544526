import { CARD_LIMIT } from './Data';

export const URL_GET_PRODUCT_GENERAL = `/product-categories`;
export const URL_GET_PRODUCT_GENERAL_BRAND = `/product-brands/%(id)s/product-subcategories?_order=asc&_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PU_BRAND = `/beautiful-numbers/clusters?_order=asc&_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PU_SUBCATEGORY = `/beautiful-numbers/clusters?_order=asc&_limit=${CARD_LIMIT}%(params)s`;
export const URL_CREATE_PU_BRAND = '/product-brands';
export const URL_PU_SUBCATEGORIES = '/product-subcategories';
export const URL_GET_PU_PRODUCT = `/products?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PU_TRX_BY_SUBCATEGORY = `/transaction/generals?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_PRODUCT = '/products';
export const URL_UPDATE_TRX_ITEM = '/transaction/generals';

export const URL_GET_NC_BY_CID = `/beautiful-numbers?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_DETAIL_TRX_NC = `/beautiful-numbers/transaction-items/%(id)s`;
export const URL_BULK_CREATE_NC = '/beautiful-numbers/bulk-create';
export const URL_BULK_STATUS_NC = '/beautiful-numbers/bulk-update-sold';
export const URL_GET_NC_COUNTER =
    '/beautiful-numbers/counter?cluster_id=%(cluster_id)s';

// Types
export const types = {
    GET_PU_SUBCATEGORY_LOADING: 'GET_PU_SUBCATEGORY_LOADING',
    GET_PU_SUBCATEGORY_SUCCESS: 'GET_PU_SUBCATEGORY_SUCCESS',
    GET_PU_SUBCATEGORY_FAILED: 'GET_PU_SUBCATEGORY_FAILED',

    GET_PU_PRODUCT_SUBCATEGORY_LOADING: 'GET_PU_PRODUCT_SUBCATEGORY_LOADING',
    GET_PU_PRODUCT_SUBCATEGORY_SUCCESS: 'GET_PU_PRODUCT_SUBCATEGORY_SUCCESS',
    GET_PU_PRODUCT_SUBCATEGORY_FAILED: 'GET_PU_PRODUCT_SUBCATEGORY_FAILED',

    GET_PU_SUBCATEGORY_TRANSACTION_LOADING:
        'GET_PU_SUBCATEGORY_TRANSACTION_LOADING',
    GET_PU_SUBCATEGORY_TRANSACTION_SUCCESS:
        'GET_PU_SUBCATEGORY_TRANSACTION_SUCCESS',
    GET_PU_SUBCATEGORY_TRANSACTION_FAILED:
        'GET_PU_SUBCATEGORY_TRANSACTION_FAILED',

    GET_DETAIL_TRX_NC_LOADING: 'GET_DETAIL_TRX_NC_LOADING',
    GET_DETAIL_TRX_NC_SUCCESS: 'GET_DETAIL_TRX_NC_SUCCESS',
    GET_DETAIL_TRX_NC_FAILED: 'GET_DETAIL_TRX_NC_FAILED',

    GET_DETAIL_PU_LOADING: 'GET_DETAIL_PU_LOADING',
    GET_DETAIL_PU_SUCCESS: 'GET_DETAIL_PU_SUCCESS',
    GET_DETAIL_PU_FAILED: 'GET_DETAIL_PU_FAILED',

    UPDATE_PU_SUBCATEGORY_LOADING: ' UPDATE_PU_SUBCATEGORY_LOADING',
    UPDATE_PU_SUBCATEGORY_SUCCESS: ' UPDATE_PU_SUBCATEGORY_SUCCESS',
    UPDATE_PU_SUBCATEGORY_FAILED: ' UPDATE_PU_SUBCATEGORY_FAILED',

    UPDATE_PU_PRODUCT_LOADING: ' UPDATE_PU_PRODUCT_LOADING',
    UPDATE_PU_PRODUCT_SUCCESS: ' UPDATE_PU_PRODUCT_SUCCESS',
    UPDATE_PU_PRODUCT_FAILED: ' UPDATE_PU_PRODUCT_FAILED',

    DELETE_PU_SUBCATEGORY_LOADING: ' DELETE_PU_SUBCATEGORY_LOADING',
    DELETE_PU_SUBCATEGORY_SUCCESS: ' DELETE_PU_SUBCATEGORY_SUCCESS',
    DELETE_PU_SUBCATEGORY_FAILED: ' DELETE_PU_SUBCATEGORY_FAILED',

    BULK_CREATE_PU_LOADING: 'BULK_CREATE_PU_LOADING',
    BULK_CREATE_PU_SUCCESS: 'BULK_CREATE_PU_SUCCESS',
    BULK_CREATE_PU_FAILED: 'BULK_CREATE_PU_FAILED',

    UPDATE_STATUS_TRANSACTION_LOADING: 'UPDATE_STATUS_TRANSACTION_LOADING',
    UPDATE_STATUS_TRANSACTION_SUCCESS: 'UPDATE_STATUS_TRANSACTION_SUCCESS',
    UPDATE_STATUS_TRANSACTION_FAILED: 'UPDATE_STATUS_TRANSACTION_FAILED',

    GET_COUNTER_TRX_NC_LOADING: 'GET_COUNTER_TRX_NC_LOADING',
    GET_COUNTER_TRX_NC_SUCCESS: 'GET_COUNTER_TRX_NC_SUCCESS',
    GET_COUNTER_TRX_NC_FAILED: 'GET_COUNTER_TRX_NC_FAILED',
};
