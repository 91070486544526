import { types } from 'constants/BosVideo';

const initialState = {
    bosVideo: {
        list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        onUpdate: {
            loading: false,
            error: '',
        },
        onDelete: {
            loading: false,
            error: '',
        },
        detail: {
            loading: false,
            data: {},
            error: '',
        },
    },
};

const bosVideo = (state = initialState.bosVideo, action) => {
    switch (action.type) {
        case types.GET_BOSVIDEO_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_BOSVIDEO_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state?.list?.page,
                },
            };
        case types.GET_BOSVIDEO_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.CREATE_BOSVIDEO_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.CREATE_BOSVIDEO_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.CREATE_BOSVIDEO_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_DETAIL_BOSVIDEO_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.GET_DETAIL_BOSVIDEO_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.GET_DETAIL_BOSVIDEO_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_BOSVIDEO_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_BOSVIDEO_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_BOSVIDEO_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        default:
            return state;
    }
};

export default bosVideo;
