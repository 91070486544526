import { CARD_LIMIT } from './Data';
export const URL_INDOHOME = `/indihome-registrations`
export const URL_INDIHOME_ID = `/indihome-registrations/%(id)s`
export const URL_INDOHOME_LIST = `${URL_INDOHOME}?_limit=${CARD_LIMIT}%(params)s`

export const types = {
    GET_INDIHOME_LOADING: 'GET_INDIHOME_LOADING',
    GET_INDIHOME_SUCCESS: 'GET_INDIHOME_SUCCESS',
    GET_INDIHOME_FAILED: 'GET_INDIHOME_FAILED',

    GET_DETAIL_INDIHOME_LOADING: 'GET_DETAIL_INDIHOME_LOADING',
    GET_DETAIL_INDIHOME_SUCCESS: 'GET_DETAIL_INDIHOME_SUCCESS',
    GET_DETAIL_INDIHOME_FAILED: 'GET_DETAIL_INDIHOME_FAILED',

    CREATE_INDIHOME_LOADING: 'CREATE_INDIHOME_LOADING',
    CREATE_INDIHOME_SUCCESS: 'CREATE_INDIHOME_SUCCESS',
    CREATE_INDIHOME_FAILED: 'CREATE_INDIHOME_FAILED',

    DELETE_INDIHOME_LOADING: 'DELETE_INDIHOME_LOADING',
    DELETE_INDIHOME_SUCCESS: 'DELETE_INDIHOME_SUCCESS',
    DELETE_INDIHOME_FAILED: 'DELETE_INDIHOME_FAILED',

    UPDATE_INDIHOME_LOADING: 'UPDATE_INDIHOME_LOADING',
    UPDATE_INDIHOME_SUCCESS: 'UPDATE_INDIHOME_SUCCESS',
    UPDATE_INDIHOME_FAILED: 'UPDATE_INDIHOME_FAILED',
}
