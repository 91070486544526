import { types } from 'constants/Product';

const initialState = {
    product: {
        list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        listPrice: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        listVoucher: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        listVoucherSummary: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        listVoucherHistory: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        listProductVoucher: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        onCreate: {
            loading: false,
            error: '',
        },
        onDelete: {
            loading: false,
            error: '',
        },
        onSetAllPrice: {
            loading: false,
            error: '',
        },
        onUpdate: {
            loading: false,
            error: '',
        },
        onUpdatePrice: {
            loading: false,
            error: '',
        },
        detail: {
            loading: false,
            error: '',
            data: {},
        },
        onCreateVoucher: {
            loading: false,
            error: '',
        },
        updateRetur: {
            loading: false,
            error: '',
        },
        countVoucher: {
            loading: false,
            data: {},
        },
        onRecall: {
            loading: false,
            error: '',
            id: '',
        },
    },
};

const product = (state = initialState.product, action) => {
    switch (action.type) {
        case types.CREATE_PHYSICAL_VOUCHER_LOADING:
            return {
                ...state,
                onCreateVoucher: {
                    ...state.onCreateVoucher,
                    loading: true,
                },
            };
        case types.CREATE_PHYSICAL_VOUCHER_SUCCESS:
            return {
                ...state,
                onCreateVoucher: {
                    ...state.onCreateVoucher,
                    loading: false,
                },
            };
        case types.CREATE_PHYSICAL_VOUCHER_FAILED:
            return {
                ...state,
                onCreateVoucher: {
                    ...state.onCreateVoucher,
                    loading: false,
                    error: action.message,
                },
            };

        case types.GET_RECALL_VOUCHER_FISIK_LOADING:
            return {
                ...state,
                onRecall: {
                    ...state.onRecall,
                    id: action.id,
                    loading: true,
                },
            };
        case types.GET_RECALL_VOUCHER_FISIK_SUCCESS:
            return {
                ...state,
                onRecall: {
                    ...state.onRecall,
                    id: '',
                    loading: false,
                },
            };
        case types.GET_RECALL_VOUCHER_FISIK_FAILED:
            return {
                ...state,
                onRecall: {
                    ...state.onRecall,
                    id: '',
                    loading: false,
                    error: action.message,
                },
            };

        case types.GET_COUNT_VOUCHER_LOADING:
            return {
                ...state,
                countVoucher: {
                    ...state.countVoucher,
                    loading: true,
                },
            };
        case types.GET_COUNT_VOUCHER_SUCCESS:
            return {
                ...state,
                countVoucher: {
                    ...state.countVoucher,
                    loading: false,
                    data: action.data,
                },
            };
        case types.GET_COUNT_VOUCHER_FAILED:
            return {
                ...state,
                countVoucher: {
                    ...state.countVoucher,
                    loading: false,
                },
            };
        case types.GET_PRODUCT_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_PRODUCT_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.list.page,
                },
            };
        case types.GET_PRODUCT_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_CLUSTER_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_CLUSTER_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.list.page,
                },
            };
        case types.GET_CLUSTER_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_DIGIPOS_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_DIGIPOS_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.list.page,
                },
            };
        case types.GET_DIGIPOS_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_REGENCY_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_REGENCY_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.list.page,
                },
            };
        case types.GET_REGENCY_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_VOUCHER_HISTORY_LOADING:
            return {
                ...state,
                listVoucherHistory: {
                    ...state.listVoucherHistory,
                    loading: true,
                },
            };
        case types.GET_VOUCHER_HISTORY_SUCCESS:
            return {
                ...state,
                listVoucherHistory: {
                    ...state.listVoucherHistory,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listVoucherHistory.page,
                },
            };
        case types.GET_VOUCHER_HISTORY_FAILED:
            return {
                ...state,
                listVoucherHistory: {
                    ...state.listVoucherHistory,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_LOADING:
            return {
                ...state,
                listVoucher: {
                    ...state.listVoucher,
                    loading: true,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_SUCCESS:
            return {
                ...state,
                listVoucher: {
                    ...state.listVoucher,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listVoucher.page,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_FAILED:
            return {
                ...state,
                listVoucher: {
                    ...state.listVoucher,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_SUMMARY_LOADING:
            return {
                ...state,
                listVoucherSummary: {
                    ...state.listVoucherSummary,
                    loading: true,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_SUMMARY_SUCCESS:
            return {
                ...state,
                listVoucherSummary: {
                    ...state.listVoucherSummary,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listVoucherSummary.page,
                },
            };
        case types.GET_PHYSICAL_VOUCHER_SUMMARY_FAILED:
            return {
                ...state,
                listVoucherSummary: {
                    ...state.listVoucherSummary,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PRODUCT_PRICE_LOADING:
            return {
                ...state,
                listPrice: {
                    ...state.listPrice,
                    loading: true,
                },
            };
        case types.GET_PRODUCT_PRICE_SUCCESS:
            return {
                ...state,
                listPrice: {
                    ...state.listPrice,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listPrice.page,
                },
            };
        case types.GET_FIND_PRODUCT_VOUCHER_LOADING:
            return {
                ...state,
                listProductVoucher: {
                    ...state.listProductVoucher,
                    loading: true,
                },
            };
        case types.GET_FIND_PRODUCT_VOUCHER_SUCCESS:
            return {
                ...state,
                listProductVoucher: {
                    ...state.listProductVoucher,
                    loading: false,
                    data: action.data,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    page: action.page || state.listProductVoucher.page,
                },
            };
        case types.GET_FIND_PRODUCT_VOUCHER_FAILED:
            return {
                ...state,
                listProductVoucher: {
                    ...state.listProductVoucher,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PRODUCT_PRICE_FAILED:
            return {
                ...state,
                listPrice: {
                    ...state.listPrice,
                    loading: false,
                    data: [],
                    error: action.message,
                },
            };
        case types.CREATE_PRODUCT_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.CREATE_PRODUCT_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.CREATE_PRODUCT_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.CREATE_CLUSTER_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.CREATE_CLUSTER_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.CREATE_CLUSTER_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_CLUSTER_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_CLUSTER_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_CLUSTER_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_DIGIPOS_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_DIGIPOS_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_DIGIPOS_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };
        case types.CHANGE_DEFAULT_DIGIPOS_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.CHANGE_DEFAULT_DIGIPOS_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.CHANGE_DEFAULT_DIGIPOS_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };
        case types.CHANGE_ACTIVE_DIGIPOS_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.CHANGE_ACTIVE_DIGIPOS_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.CHANGE_ACTIVE_DIGIPOS_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_PRODUCT_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_PRODUCT_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_PRODUCT_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };

        case types.SHOW_PRODUCT_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.SHOW_PRODUCT_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_PRODUCT_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };
        case types.SHOW_CLUSTER_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.SHOW_CLUSTER_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_CLUSTER_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };
        case types.SHOW_DIGIPOS_LOADING:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: true,
                },
            };
        case types.SHOW_DIGIPOS_SUCCESS:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    data: action.data,
                    loading: false,
                },
            };
        case types.SHOW_DIGIPOS_FAILED:
            return {
                ...state,
                detail: {
                    ...state.detail,
                    loading: false,
                    error: action.message,
                },
            };
        case types.CREATE_DIGIPOS_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.CREATE_DIGIPOS_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.CREATE_DIGIPOS_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_PRODUCT_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_PRODUCT_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_PRODUCT_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_CLUSTER_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_CLUSTER_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_CLUSTER_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_DIGIPOS_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_DIGIPOS_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_DIGIPOS_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_PRODUCT_PRICE_LOADING:
            return {
                ...state,
                onUpdatePrice: {
                    ...state.onUpdatePrice,
                    loading: true,
                },
            };
        case types.UPDATE_PRODUCT_PRICE_SUCCESS:
            return {
                ...state,
                onUpdatePrice: {
                    ...state.onUpdatePrice,
                    loading: false,
                },
            };
        case types.UPDATE_PRODUCT_PRICE_FAILED:
            return {
                ...state,
                onUpdatePrice: {
                    ...state.onUpdatePrice,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_MULTIPLE_RETURN_LOADING:
            return {
                ...state,
                updateRetur: {
                    ...state.updateRetur,
                    loading: true,
                },
            };
        case types.UPDATE_MULTIPLE_RETURN_SUCCESS:
            return {
                ...state,
                updateRetur: {
                    ...state.updateRetur,
                    loading: false,
                },
            };
        case types.UPDATE_MULTIPLE_RETURN_FAILED:
            return {
                ...state,
                updateRetur: {
                    ...state.updateRetur,
                    loading: false,
                    error: action.message,
                },
            };
        case types.SET_ALL_PRICE_PRODUCT_LOADING:
            return {
                ...state,
                onSetAllPrice: {
                    ...state.onSetAllPrice,
                    loading: true,
                },
            };
        case types.SET_ALL_PRICE_PRODUCT_SUCCESS:
            return {
                ...state,
                onSetAllPrice: {
                    ...state.onSetAllPrice,
                    loading: false,
                },
            };
        case types.SET_ALL_PRICE_PRODUCT_FAILED:
            return {
                ...state,
                onSetAllPrice: {
                    ...state.onSetAllPrice,
                    loading: false,
                    error: action.message,
                },
            };
        default:
            return state;
    }
};

export default product;
