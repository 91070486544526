import { types } from 'constants/Indihome';

const initialState = {
    indihome: {
        list: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        onDelete: {
            loading: false,
            error: '',
        },
        onUpdate: {
            loading: false,
            error: '',
        },
    },
};

const indihomeReducer = (state = initialState.indihome, action) => {
    switch (action.type) {
        case types.GET_INDIHOME_LOADING:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: true,
                },
            };
        case types.GET_INDIHOME_SUCCESS:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.list?.page,
                },
            };
        case types.GET_INDIHOME_FAILED:
            return {
                ...state,
                list: {
                    ...state.list,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_INDIHOME_LOADING:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: true,
                },
            };
        case types.DELETE_INDIHOME_SUCCESS:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                },
            };
        case types.DELETE_INDIHOME_FAILED:
            return {
                ...state,
                onDelete: {
                    ...state.onDelete,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_INDIHOME_LOADING:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: true,
                },
            };
        case types.UPDATE_INDIHOME_SUCCESS:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                },
            };
        case types.UPDATE_INDIHOME_FAILED:
            return {
                ...state,
                onUpdate: {
                    ...state.onUpdate,
                    loading: false,
                    error: action.message,
                },
            };
        default:
            return state;
    }
};

export default indihomeReducer;
