import { types } from 'constants/ProdukUmum';

const initialState = {
    pu: {
        subCategory: {
            loading: false,
            error: '',
            data: [],
            brandID: null,
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        productSubCategory: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        detailSubcategory: {
            loading: false,
            error: '',
            data: {},
        },
        onUpdateSubcategory: {
            loading: false,
            error: '',
        },
        onUpdateProduct: {
            loading: false,
            error: '',
        },
        onDeleteSubcategory: {
            loading: false,
            error: '',
        },
        transactions: {
            loading: false,
            error: '',
            data: [],
            page: 1,
            totalPage: null,
            totalData: 0,
        },
        transaction: {
            loading: false,
            data: {},
            error: '',
        },
        counter: {
            loading: false,
            data: {},
            error: '',
        },
        onCreate: {
            loading: false,
            error: '',
        },
        onUpdateTransaction: {
            loading: false,
            error: '',
        },
    },
};

const puReducer = (state = initialState.pu, action) => {
    switch (action.type) {
        case types.GET_PU_SUBCATEGORY_LOADING:
            return {
                ...state,
                subCategory: {
                    ...state.subCategory,
                    loading: true,
                },
            };
        case types.GET_PU_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                subCategory: {
                    ...state.subCategory,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    brandID: action.brandID,
                    page: action.page || state?.subCategory?.page,
                },
            };
        case types.GET_PU_SUBCATEGORY_FAILED:
            return {
                ...state,
                subCategory: {
                    ...state.subCategory,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_DETAIL_TRX_NC_LOADING:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    loading: true,
                },
            };
        case types.GET_DETAIL_TRX_NC_SUCCESS:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    data: action.data,
                    loading: false,
                },
            };
        case types.GET_DETAIL_TRX_NC_FAILED:
            return {
                ...state,
                transaction: {
                    ...state.transaction,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PU_PRODUCT_SUBCATEGORY_LOADING:
            return {
                ...state,
                productSubCategory: {
                    ...state.productSubCategory,
                    loading: true,
                },
            };
        case types.GET_PU_PRODUCT_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                productSubCategory: {
                    ...state.productSubCategory,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.productSubCategory?.page,
                },
            };
        case types.GET_PU_PRODUCT_SUBCATEGORY_FAILED:
            return {
                ...state,
                productSubCategory: {
                    ...state.productSubCategory,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_PU_SUBCATEGORY_TRANSACTION_LOADING:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: true,
                },
            };
        case types.GET_PU_SUBCATEGORY_TRANSACTION_SUCCESS:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: false,
                    totalPage: parseInt(action.totalPage),
                    totalData: parseInt(action.totalData),
                    data: action.data,
                    page: action.page || state?.transactions?.page,
                },
            };
        case types.GET_PU_SUBCATEGORY_TRANSACTION_FAILED:
            return {
                ...state,
                transactions: {
                    ...state.transactions,
                    loading: false,
                    error: action.message,
                },
            };

        case types.GET_DETAIL_PU_LOADING:
            return {
                ...state,
                detailSubcategory: {
                    ...state.detailSubcategory,
                    loading: true,
                },
            };
        case types.GET_DETAIL_PU_SUCCESS:
            return {
                ...state,
                detailSubcategory: {
                    ...state.detailSubcategory,
                    data: action.data,
                    loading: false,
                },
            };
        case types.GET_DETAIL_PU_FAILED:
            return {
                ...state,
                detailSubcategory: {
                    ...state.detailSubcategory,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_PU_SUBCATEGORY_LOADING:
            return {
                ...state,
                onUpdateSubcategory: {
                    ...state.onUpdateSubcategory,
                    loading: true,
                },
            };
        case types.UPDATE_PU_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                onUpdateSubcategory: {
                    ...state.onUpdateSubcategory,
                    loading: false,
                },
            };
        case types.UPDATE_PU_SUBCATEGORY_FAILED:
            return {
                ...state,
                onUpdateSubcategory: {
                    ...state.onUpdateSubcategory,
                    loading: false,
                    error: action.message,
                },
            };
        case types.UPDATE_PU_PRODUCT_LOADING:
            return {
                ...state,
                onUpdateProduct: {
                    ...state.onUpdateProduct,
                    loading: true,
                },
            };
        case types.UPDATE_PU_PRODUCT_SUCCESS:
            return {
                ...state,
                onUpdateProduct: {
                    ...state.onUpdateProduct,
                    loading: false,
                },
            };
        case types.UPDATE_PU_PRODUCT_FAILED:
            return {
                ...state,
                onUpdateProduct: {
                    ...state.onUpdateProduct,
                    loading: false,
                    error: action.message,
                },
            };
        case types.DELETE_PU_SUBCATEGORY_LOADING:
            return {
                ...state,
                onDeleteSubcategory: {
                    ...state.onDeleteSubcategory,
                    loading: true,
                },
            };
        case types.DELETE_PU_SUBCATEGORY_SUCCESS:
            return {
                ...state,
                onDeleteSubcategory: {
                    ...state.onDeleteSubcategory,
                    loading: false,
                },
            };
        case types.DELETE_PU_SUBCATEGORY_FAILED:
            return {
                ...state,
                onDeleteSubcategory: {
                    ...state.onDeleteSubcategory,
                    loading: false,
                    error: action.message,
                },
            };
        case types.GET_COUNTER_TRX_NC_LOADING:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    loading: true,
                },
            };
        case types.GET_COUNTER_TRX_NC_SUCCESS:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    data: action.data,
                    loading: false,
                },
            };
        case types.GET_COUNTER_TRX_NC_FAILED:
            return {
                ...state,
                counter: {
                    ...state.counter,
                    loading: false,
                    error: action.message,
                },
            };
        case types.BULK_CREATE_PU_LOADING:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: true,
                },
            };
        case types.BULK_CREATE_PU_SUCCESS:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                },
            };
        case types.BULK_CREATE_PU_FAILED:
            return {
                ...state,
                onCreate: {
                    ...state.onCreate,
                    loading: false,
                    error: action.message,
                },
            };

        case types.UPDATE_STATUS_TRANSACTION_LOADING:
            return {
                ...state,
                onUpdateTransaction: {
                    ...state.onUpdateTransaction,
                    loading: true,
                },
            };
        case types.UPDATE_STATUS_TRANSACTION_SUCCESS:
            return {
                ...state,
                onUpdateTransaction: {
                    ...state.onUpdateTransaction,
                    loading: false,
                },
            };
        case types.UPDATE_STATUS_TRANSACTION_FAILED:
            return {
                ...state,
                onUpdateTransaction: {
                    ...state.onUpdateTransaction,
                    loading: false,
                    error: action.message,
                },
            };

        default:
            return state;
    }
};

export default puReducer;
