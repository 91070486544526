import { combineReducers } from 'redux';
import auth from 'redux/reducers/Auth';
import setting from 'redux/reducers/Setting';
import sales from 'redux/reducers/Sales';
import outlet from 'redux/reducers/Outlet';
import bosPoin from 'redux/reducers/BosPoin';
import bosVideo from 'redux/reducers/BosVideo';
import dynamicBanner from 'redux/reducers/DynamicBanner';
import select from 'redux/reducers/Select';
import kind from 'redux/reducers/Kind';
import topup from 'redux/reducers/TopUp';
import product from 'redux/reducers/Product';
import promo from 'redux/reducers/Promo';
import hadiah from 'redux/reducers/Hadiah';
import transaction from 'redux/reducers/Transaction';
import articles from 'redux/reducers/Articles';
import mutasi from 'redux/reducers/Mutasi';
import mutasiPoint from 'redux/reducers/MutasiPoint';
import account from 'redux/reducers/Account';
import supportTicket from 'redux/reducers/SupportTicket';
import activityLogs from 'redux/reducers/ActivityLogs';
import notification from 'redux/reducers/Notification';
import transfer from 'redux/reducers/Transfer';
import app from 'redux/reducers/App';
import wisgames from 'redux/reducers/Wisgames';
import merchant from 'redux/reducers/Merchant';
import ncReducer from 'redux/reducers/NomorCantik';
import puReducer from 'redux/reducers/ProdukUmum';
import indihomeReducer from 'redux/reducers/Indihome';
import masterData from 'redux/reducers/MasterData';

export default combineReducers({
    auth,
    setting,
    select,
    kind,
    sales,
    outlet,
    bosPoin,
    bosVideo,
    dynamicBanner,
    topup,
    product,
    promo,
    hadiah,
    transaction,
    articles,
    mutasi,
    mutasiPoint,
    account,
    supportTicket,
    activityLogs,
    notification,
    transfer,
    app,
    wisgames,
    merchant,
    nc: ncReducer,
    pu: puReducer,
    indihome: indihomeReducer,
    masterData,
});
