import { CARD_LIMIT } from "./Data";
export const URL_GET_ARTICLE = `/articles?_limit=${CARD_LIMIT}%(params)s`;
// export const URL_ARTICLE = `/article-categories/%(id)s/articles`;
export const URL_ARTICLE = "/articles";
export const URL_DETAIL_ARTICLE = `/articles/%(id)s`;
export const URL_ARTICLE_REGENCY = `/articles/%(id)s/articles-regencies?_limit=${CARD_LIMIT}`;

export const types = {
  GET_ARTICLE_LOADING: "GET_ARTICLE_LOADING",
  GET_ARTICLE_SUCCESS: "GET_ARTICLE_SUCCESS",
  GET_ARTICLE_FAILED: "GET_ARTICLE_FAILED",

  CREATE_ARTICLE_LOADING: "CREATE_ARTICLE_LOADING",
  CREATE_ARTICLE_SUCCESS: "CREATE_ARTICLE_SUCCESS",
  CREATE_ARTICLE_FAILED: "CREATE_ARTICLE_FAILED",

  SHOW_ARTICLE_LOADING: "SHOW_ARTICLE_LOADING",
  SHOW_ARTICLE_SUCCESS: "SHOW_ARTICLE_SUCCESS",
  SHOW_ARTICLE_FAILED: "SHOW_ARTICLE_FAILED",

  DELETE_ARTICLE_LOADING: "DELETE_ARTICLE_LOADING",
  DELETE_ARTICLE_SUCCESS: "DELETE_ARTICLE_SUCCESS",
  DELETE_ARTICLE_FAILED: "DELETE_ARTICLE_FAILED",

  UPDATE_ARTICLE_LOADING: "UPDATE_ARTICLE_LOADING",
  UPDATE_ARTICLE_SUCCESS: "UPDATE_ARTICLE_SUCCESS",
  UPDATE_ARTICLE_FAILED: "UPDATE_ARTICLE_FAILED",

  REGENCY_ARTICLE_LOADING: "REGENCY_ARTICLE_LOADING",
  REGENCY_ARTICLE_SUCCESS: "REGENCY_ARTICLE_SUCCESS",
  REGENCY_ARTICLE_FAILED: "REGENCY_ARTICLE_FAILED",
};
