// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_DYNAMIC_BANNER = `/banners?_limit=${CARD_LIMIT}%(params)s`;
export const URL_DYNAMIC_BANNER = `/banners`;
export const URL_DETAIL_DYNAMIC_BANNER = `/banners/%(id)s`;
// Types
export const types = {
    GET_DYNAMIC_BANNER_LOADING: 'GET_DYNAMIC_BANNER_LOADING',
    GET_DYNAMIC_BANNER_SUCCESS: 'GET_DYNAMIC_BANNER_SUCCESS',
    GET_DYNAMIC_BANNER_FAILED: 'GET_DYNAMIC_BANNER_FAILED',

    CREATE_DYNAMIC_BANNER_LOADING: 'CREATE_DYNAMIC_BANNER_LOADING',
    CREATE_DYNAMIC_BANNER_SUCCESS: 'CREATE_DYNAMIC_BANNER_SUCCESS',
    CREATE_DYNAMIC_BANNER_FAILED: 'CREATE_DYNAMIC_BANNER_FAILED',

    DELETE_DYNAMIC_BANNER_LOADING: 'DELETE_DYNAMIC_BANNER_LOADING',
    DELETE_DYNAMIC_BANNER_SUCCESS: 'DELETE_DYNAMIC_BANNER_SUCCESS',
    DELETE_DYNAMIC_BANNER_FAILED: 'DELETE_DYNAMIC_BANNER_FAILED',

    GET_DETAIL_DYNAMIC_BANNER_LOADING: 'GET_DETAIL_DYNAMIC_BANNER_LOADING',
    GET_DETAIL_DYNAMIC_BANNER_SUCCESS: 'GET_DETAIL_DYNAMIC_BANNER_SUCCESS',
    GET_DETAIL_DYNAMIC_BANNER_FAILED: 'GET_DETAIL_DYNAMIC_BANNER_FAILED',

    UPDATE_DYNAMIC_BANNER_LOADING: ' UPDATE_DYNAMIC_BANNER_LOADING',
    UPDATE_DYNAMIC_BANNER_SUCCESS: ' UPDATE_DYNAMIC_BANNER_SUCCESS',
    UPDATE_DYNAMIC_BANNER_FAILED: ' UPDATE_DYNAMIC_BANNER_FAILED',
};
