// URL
import { CARD_LIMIT } from './Data';
export const URL_GET_TRANSACTION = `/transactions?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_TRANSACTION_MODEM = `/transaction/modems?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_TRANSACTION_PERDANA = `/transaction/sim-cards?_limit=${CARD_LIMIT}%(params)s`;
export const URL_DETAIL_TRANSACTION = `/transactions/%(id)s`;
export const URL_REPORTING_TRANSACTION = `/transactions/reports?_limit=10%(params)s`;
export const URL_TRANSACTION_STATUS = `/transactions/%(id)s/status`;
export const URL_TRANSACTION_MODEM_STATUS = `/transaction/modems/%(id)s/status`;
export const URL_TRANSACTION_PERDANA_STATUS = `/transaction/sim-cards/%(id)s/status`;

// Types
export const types = {
   GET_TRANSACTION_LOADING: 'GET_TRANSACTION_LOADING',
   GET_TRANSACTION_SUCCESS: 'GET_TRANSACTION_SUCCESS',
   GET_TRANSACTION_FAILED: 'GET_TRANSACTION_FAILED',

   GET_TRANSACTION_PERDANA_LOADING: 'GET_TRANSACTION_PERDANA_LOADING',
   GET_TRANSACTION_PERDANA_SUCCESS: 'GET_TRANSACTION_PERDANA_SUCCESS',
   GET_TRANSACTION_PERDANA_FAILED: 'GET_TRANSACTION_PERDANA_FAILED',

   GET_TRANSACTION_MODEM_LOADING: 'GET_TRANSACTION_MODEM_LOADING',
   GET_TRANSACTION_MODEM_SUCCESS: 'GET_TRANSACTION_MODEM_SUCCESS',
   GET_TRANSACTION_MODEM_FAILED: 'GET_TRANSACTION_MODEM_FAILED',

   SET_TRANSACTION_STATUS_LOADING: 'SET_TRANSACTION_STATUS_LOADING',
   SET_TRANSACTION_STATUS_SUCCESS: 'SET_TRANSACTION_STATUS_SUCCESS',
   SET_TRANSACTION_STATUS_FAILED: 'SET_TRANSACTION_STATUS_FAILED',

   SET_TRANSACTION_MODEM_STATUS_LOADING: 'SET_TRANSACTION_MODEM_STATUS_LOADING',
   SET_TRANSACTION_MODEM_STATUS_SUCCESS: 'SET_TRANSACTION_MODEM_STATUS_SUCCESS',
   SET_TRANSACTION_MODEM_STATUS_FAILED: 'SET_TRANSACTION_MODEM_STATUS_FAILED',

   SET_TRANSACTION_PERDANA_STATUS_LOADING:
      'SET_TRANSACTION_PERDANA_STATUS_LOADING',
   SET_TRANSACTION_PERDANA_STATUS_SUCCESS:
      'SET_TRANSACTION_PERDANA_STATUS_SUCCESS',
   SET_TRANSACTION_PERDANA_STATUS_FAILED:
      'SET_TRANSACTION_PERDANA_STATUS_FAILED',
};
