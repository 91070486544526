// URL

export const URL_GET_CATEGORIES = `/product-categories?%(params)s`;
export const URL_DETAIL_CATEGORIES = `/product-categories/%(id)s`;
export const URL_CATEGORIES = `/product-categories`;

export const URL_GET_BRAND = `/product-brands?%(params)s`;
export const URL_DETAIL_BRAND = `/product-brands/%(id)s`;
export const URL_BRAND = `/product-brands`;

export const URL_GET_SUBCAT = `/product-subcategories?%(params)s`;
export const URL_DETAIL_SUBCAT = `/product-subcategories/%(id)s`;
// Types
export const types = {
    GET_CATEGORY_LOADING: 'GET_CATEGORY_LOADING',
    GET_CATEGORY_SUCCESS: 'GET_CATEGORY_SUCCESS',
    GET_CATEGORY_FAILED: 'GET_CATEGORY_FAILED',

    SHOW_CATEGORY_LOADING: 'SHOW_CATEGORY_LOADING',
    SHOW_CATEGORY_SUCCESS: 'SHOW_CATEGORY_SUCCESS',
    SHOW_CATEGORY_FAILED: 'SHOW_CATEGORY_FAILED',

    CREATE_CATEGORY_LOADING: 'CREATE_CATEGORY_LOADING',
    CREATE_CATEGORY_SUCCESS: 'CREATE_CATEGORY_SUCCESS',
    CREATE_CATEGORY_FAILED: 'CREATE_CATEGORY_FAILED',

    DELETE_CATEGORY_LOADING: 'DELETE_CATEGORY_LOADING',
    DELETE_CATEGORY_SUCCESS: 'DELETE_CATEGORY_SUCCESS',
    DELETE_CATEGORY_FAILED: 'DELETE_CATEGORY_FAILED',

    UPDATE_CATEGORY_LOADING: 'UPDATE_CATEGORY_LOADING',
    UPDATE_CATEGORY_SUCCESS: 'UPDATE_CATEGORY_SUCCESS',
    UPDATE_CATEGORY_FAILED: 'UPDATE_CATEGORY_FAILED',

    GET_BRAND_LOADING: 'GET_BRAND_LOADING',
    GET_BRAND_SUCCESS: 'GET_BRAND_SUCCESS',
    GET_BRAND_FAILED: 'GET_BRAND_FAILED',

    SHOW_BRAND_LOADING: 'SHOW_BRAND_LOADING',
    SHOW_BRAND_SUCCESS: 'SHOW_BRAND_SUCCESS',
    SHOW_BRAND_FAILED: 'SHOW_BRAND_FAILED',

    CREATE_BRAND_LOADING: 'CREATE_BRAND_LOADING',
    CREATE_BRAND_SUCCESS: 'CREATE_BRAND_SUCCESS',
    CREATE_BRAND_FAILED: 'CREATE_BRAND_FAILED',

    DELETE_BRAND_LOADING: 'DELETE_BRAND_LOADING',
    DELETE_BRAND_SUCCESS: 'DELETE_BRAND_SUCCESS',
    DELETE_BRAND_FAILED: 'DELETE_BRAND_FAILED',

    UPDATE_BRAND_LOADING: 'UPDATE_BRAND_LOADING',
    UPDATE_BRAND_SUCCESS: 'UPDATE_BRAND_SUCCESS',
    UPDATE_BRAND_FAILED: 'UPDATE_BRAND_FAILED',

    GET_SUBCAT_LOADING: 'GET_SUBCAT_LOADING',
    GET_SUBCAT_SUCCESS: 'GET_SUBCAT_SUCCESS',
    GET_SUBCAT_FAILED: 'GET_SUBCAT_FAILED',

    SHOW_SUBCAT_LOADING: 'SHOW_SUBCAT_LOADING',
    SHOW_SUBCAT_SUCCESS: 'SHOW_SUBCAT_SUCCESS',
    SHOW_SUBCAT_FAILED: 'SHOW_SUBCAT_FAILED',

    CREATE_SUBCAT_LOADING: 'CREATE_SUBCAT_LOADING',
    CREATE_SUBCAT_SUCCESS: 'CREATE_SUBCAT_SUCCESS',
    CREATE_SUBCAT_FAILED: 'CREATE_SUBCAT_FAILED',

    DELETE_SUBCAT_LOADING: 'DELETE_SUBCAT_LOADING',
    DELETE_SUBCAT_SUCCESS: 'DELETE_SUBCAT_SUCCESS',
    DELETE_SUBCAT_FAILED: 'DELETE_SUBCAT_FAILED',

    UPDATE_SUBCAT_LOADING: 'UPDATE_SUBCAT_LOADING',
    UPDATE_SUBCAT_SUCCESS: 'UPDATE_SUBCAT_SUCCESS',
    UPDATE_SUBCAT_FAILED: 'UPDATE_SUBCAT_FAILED',
};
