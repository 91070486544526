// URL
import { CARD_LIMIT } from "./Data";
export const URL_GET_TOPUP_SALES = `/seller-wallets?_limit=${CARD_LIMIT}%(params)s&in_out=IN&kind=TOPUP`;
export const URL_GET_TOPUP_OUTLET = `/topup-requests?_limit=${CARD_LIMIT}%(params)s`;
export const URL_GET_TOPUP_VIA_HELPDESK = `/topup-requests?_limit=${CARD_LIMIT}%(params)s&kind=OUTLET_TO_ADMIN`;
export const URL_GET_TOPUP_SALDO = `/reduce-outlet-wallets?_limit=${CARD_LIMIT}%(params)s`;
export const URL_ACCEPT_TOPUP_OUTLET = `/topup-requests/%(id)s/accept`;
export const URL_DECLINE_TOPUP_OUTLET = `/topup-requests/%(id)s/decline`;
export const URL_CREATE_TOPUP_SALES = `/seller-wallets/add-amount`;
export const URL_CREATE_TOPUP_LIMIT = `/seller-wallets/set-limit`;
export const URL_CREATE_TOPUP_SALDO = `/reduce-outlet-wallets`;
export const URL_DECLINE_TOPUP_SALDO = `/reduce-outlet-wallets/%(id)s/decline`;
export const URL_ACCEPT_TOPUP_SALDO = `/reduce-outlet-wallets/%(id)s/accept`;
export const URL_TO_COLLECTED = `/reports/topup-requests/%(id)s/seller-billing`;
export const URL_TO_COLLECTED_TRANFER = `/reports/transfer-wallets/%(id)s/seller-billing`;
export const URL_TO_MULTIPLE_COLLECTED_TRANFER = `/reports/transfer-wallets/seller-billing/multiple`;
export const URL_TO_MULTIPLE_COLLECTED = `/reports/topup-requests/seller-billing/multiple`;
export const URL_TOPUP_OUTLET_REPORT = `/topup-requests/reports?%(params)s`;
export const URL_TOPUP_VIA_HELPDESK_REPORT = `/topup-requests/reports?%(params)s&kind=OUTLET_TO_ADMIN`;
export const URL_TOPUP_SALES_REPORT = `/seller-wallets/reports?%(params)s&in_out=IN&kind=TOPUP`;
export const URL_TOPUP_SALDO_REPORT = `/reduce-outlet-wallets/reports?%(params)s`;
// Types
export const types = {
  GET_TOPUP_SALES_LOADING: "GET_TOPUP_SALES_LOADING",
  GET_TOPUP_SALES_SUCCESS: "GET_TOPUP_SALES_SUCCESS",
  GET_TOPUP_SALES_FAILED: "GET_TOPUP_SALES_FAILED",

  SET_COLLECTED_SALES_LOADING: "SET_COLLECTED_SALES_LOADING",
  SET_COLLECTED_SALES_SUCCESS: "SET_COLLECTED_SALES_SUCCESS",
  SET_COLLECTED_SALES_FAILED: "SET_COLLECTED_SALES_FAILED",

  CREATE_TOPUP_SALES_LOADING: "CREATE_TOPUP_SALES_LOADING",
  CREATE_TOPUP_SALES_SUCCESS: "CREATE_TOPUP_SALES_SUCCESS",
  CREATE_TOPUP_SALES_FAILED: "CREATE_TOPUP_SALES_FAILED",

  CREATE_TOPUP_SALDO_LOADING: "CREATE_TOPUP_SALDO_LOADING",
  CREATE_TOPUP_SALDO_SUCCESS: "CREATE_TOPUP_SALDO_SUCCESS",
  CREATE_TOPUP_SALDO_FAILED: "CREATE_TOPUP_SALDO_FAILED",

  GET_TOPUP_OUTLET_LOADING: "GET_TOPUP_OUTLET_LOADING",
  GET_TOPUP_OUTLET_SUCCESS: "GET_TOPUP_OUTLET_SUCCESS",
  GET_TOPUP_OUTLET_FAILED: "GET_TOPUP_OUTLET_FAILED",

  GET_TOPUP_VIA_HELPDESK_LOADING: "GET_TOPUP_VIA_HELPDESK_LOADING",
  GET_TOPUP_VIA_HELPDESK_SUCCESS: "GET_TOPUP_VIA_HELPDESK_SUCCESS",
  GET_TOPUP_VIA_HELPDESK_FAILED: "GET_TOPUP_VIA_HELPDESK_FAILED",

  DECLINE_TOPUP_OUTLET_LOADING: "DECLINE_TOPUP_OUTLET_LOADING",
  DECLINE_TOPUP_OUTLET_SUCCESS: "DECLINE_TOPUP_OUTLET_SUCCESS",
  DECLINE_TOPUP_OUTLET_FAILED: "DECLINE_TOPUP_OUTLET_FAILED",

  ACCEPT_TOPUP_OUTLET_LOADING: "ACCEPT_TOPUP_OUTLET_LOADING",
  ACCEPT_TOPUP_OUTLET_SUCCESS: "ACCEPT_TOPUP_OUTLET_SUCCESS",
  ACCEPT_TOPUP_OUTLET_FAILED: "ACCEPT_TOPUP_OUTLET_FAILED",

  ACCEPT_MULTIPLE_TOPUP_OUTLET_LOADING: "ACCEPT_MULTIPLE_TOPUP_OUTLET_LOADING",
  ACCEPT_MULTIPLE_TOPUP_OUTLET_SUCCESS: "ACCEPT_MULTIPLE_TOPUP_OUTLET_SUCCESS",
  ACCEPT_MULTIPLE_TOPUP_OUTLET_FAILED: "ACCEPT_MULTIPLE_TOPUP_OUTLET_FAILED",

  GET_TOPUP_SALDO_LOADING: "GET_TOPUP_SALDO_LOADING",
  GET_TOPUP_SALDO_SUCCESS: "GET_TOPUP_SALDO_SUCCESS",
  GET_TOPUP_SALDO_FAILED: "GET_TOPUP_SALDO_FAILED",

  DECLINE_TOPUP_SALDO_LOADING: "DECLINE_TOPUP_SALDO_LOADING",
  DECLINE_TOPUP_SALDO_SUCCESS: "DECLINE_TOPUP_SALDO_SUCCESS",
  DECLINE_TOPUP_SALDO_FAILED: "DECLINE_TOPUP_SALDO_FAILED",

  ACCEPT_TOPUP_SALDO_LOADING: "ACCEPT_TOPUP_SALDO_LOADING",
  ACCEPT_TOPUP_SALDO_SUCCESS: "ACCEPT_TOPUP_SALDO_SUCCESS",
  ACCEPT_TOPUP_SALDO_FAILED: "ACCEPT_TOPUP_SALDO_FAILED",
};
