import { types } from "constants/Sales";

const initialState = {
   sales: {
      list: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onCreate: {
         loading: false,
         error: ""
      },
      onUpdate: {
         loading: false,
         error: ""
      },
      onSuspend: {
         loading: false,
         error: ""
      },
      onSchedule: {
         loading: false,
         error: ""
      },
      onUpdateStatus: {
         loading: false,
         error: ""
      },
      days: {
         data: [],
         loading: false,
         error: ""
      },
      listOperational: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onUpdateSchedule: {
         loading: false,
         error: ""
      },
   }
};

const sales = (state = initialState.sales, action) => {
   switch (action.type) {
      case types.GET_SALES_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true
            }
         };
      case types.GET_SALES_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.list.page
            }
         };
      case types.GET_SALES_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message
            }
         };
      case types.GET_OPERATIONAL_HOUR_LOADING:
         return {
            ...state,
            listOperational: {
               ...state.listOperational,
               loading: true
            }
         };
      case types.GET_OPERATIONAL_HOUR_SUCCESS:
         return {
            ...state,
            listOperational: {
               ...state.listOperational,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.listOperational.page
            }
         };
      case types.GET_OPERATIONAL_HOUR_FAILED:
         return {
            ...state,
            listOperational: {
               ...state.listOperational,
               loading: false,
               error: action.message
            }
         };

      case types.GET_DAYS_LOADING:
         return {
            ...state,
            days: {
               ...state.days,
               loading: true
            }
         };
      case types.GET_DAYS_SUCCESS:
         return {
            ...state,
            days: {
               ...state.days,
               loading: false,
               data: action.data
            }
         };
      case types.GET_DAYS_FAILED:
         return {
            ...state,
            days: {
               ...state.days,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_SALES_LOADING:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: true
            }
         };
      case types.CREATE_SALES_SUCCESS:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false
            }
         };
      case types.CREATE_SALES_FAILED:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
               error: action.message
            }
         };

      case types.UPDATE_SALES_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true
            }
         };
      case types.UPDATE_SALES_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false
            }
         };
      case types.UPDATE_SALES_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message
            }
         };

      case types.SUSPEND_SALES_LOADING:
         return {
            ...state,
            onSuspend: {
               ...state.onSuspend,
               loading: true
            }
         };
      case types.SUSPEND_SALES_SUCCESS:
         return {
            ...state,
            onSuspend: {
               ...state.onSuspend,
               loading: false
            }
         };
      case types.SUSPEND_SALES_FAILED:
         return {
            ...state,
            onSuspend: {
               ...state.onSuspend,
               loading: false,
               error: action.message
            }
         };

      case types.SCHEDULE_SALES_LOADING:
         return {
            ...state,
            onSchedule: {
               ...state.onSchedule,
               loading: true
            }
         };
      case types.SCHEDULE_SALES_SUCCESS:
         return {
            ...state,
            onSchedule: {
               ...state.onSchedule,
               loading: false
            }
         };
      case types.SCHEDULE_SALES_FAILED:
         return {
            ...state,
            onSchedule: {
               ...state.onSchedule,
               loading: false,
               error: action.message
            }
         };

      case types.UPDATE_STATUS_SALES_LOADING:
         return {
            ...state,
            onUpdateStatus: {
               ...state.onUpdateStatus,
               loading: true
            }
         };
      case types.UPDATE_STATUS_SALES_SUCCESS:
         return {
            ...state,
            onUpdateStatus: {
               ...state.onUpdateStatus,
               loading: false
            }
         };
      case types.UPDATE_STATUS_SALES_FAILED:
         return {
            ...state,
            onUpdateStatus: {
               ...state.onUpdateStatus,
               loading: false,
               error: action.message
            }
         };


      case types.SET_OPERATIONAL_HOUR_LOADING:
         return {
            ...state,
            onUpdateSchedule: {
               ...state.onUpdateSchedule,
               loading: true
            }
         };
      case types.SET_OPERATIONAL_HOUR_SUCCESS:
         return {
            ...state,
            onUpdateSchedule: {
               ...state.onUpdateSchedule,
               loading: false
            }
         };
      case types.SET_OPERATIONAL_HOUR_FAILED:
         return {
            ...state,
            onUpdateSchedule: {
               ...state.onUpdateSchedule,
               loading: false,
               error: action.message
            }
         };
      default:
         return state;
   }
};

export default sales;
