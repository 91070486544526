import { types } from "constants/Article";

const initialState = {
   articles: {
      list: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      },
      onCreate: {
         loading: false,
         error: ""
      },
      onUpdate: {
         loading: false,
         error: ""
      },
      onDelete: {
         loading: false,
         error: ""
      },
      detail: {
         loading: false,
         data: {},
         error: ""
      },
      regency: {
         loading: false,
         error: "",
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0
      }
   }
};

const articles = (state = initialState.articles, action) => {
   switch (action.type) {
      case types.GET_ARTICLE_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true
            }
         };
      case types.GET_ARTICLE_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.list.page
            }
         };
      case types.GET_ARTICLE_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message
            }
         };

      case types.DELETE_ARTICLE_LOADING:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: true
            }
         };
      case types.DELETE_ARTICLE_SUCCESS:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false
            }
         };
      case types.DELETE_ARTICLE_FAILED:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false,
               error: action.message
            }
         };

      case types.CREATE_ARTICLE_LOADING:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: true
            }
         };
      case types.CREATE_ARTICLE_SUCCESS:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false
            }
         };
      case types.CREATE_ARTICLE_FAILED:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
               error: action.message
            }
         };

      case types.UPDATE_ARTICLE_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true
            }
         };
      case types.UPDATE_ARTICLE_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false
            }
         };
      case types.UPDATE_ARTICLE_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message
            }
         };
      case types.REGENCY_ARTICLE_LOADING:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: true
            }
         };
      case types.REGENCY_ARTICLE_SUCCESS:
         return {
            ...state,
            regency: {
               ...state.regency,
               data: action.data,
               loading: false
            }
         };
      case types.REGENCY_ARTICLE_FAILED:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               error: action.message
            }
         };

      case types.SHOW_ARTICLE_LOADING:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: true
            }
         };
      case types.SHOW_ARTICLE_SUCCESS:
         return {
            ...state,
            detail: {
               ...state.detail,
               data: action.data,
               loading: false
            }
         };
      case types.SHOW_ARTICLE_FAILED:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               error: action.message
            }
         };

      default:
         return state;
   }
};

export default articles;
