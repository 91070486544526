import { types } from 'constants/Hadiah';

const initialState = {
   hadiah: {
      list: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      listRedeem: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      listLotteries: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
      onCrete: {
         loading: false,
         error: '',
      },
      onDelivery: {
         loading: false,
         error: '',
      },
      onDelete: {
         loading: false,
         error: '',
      },
      onUpdate: {
         loading: false,
         error: '',
      },
      detail: {
         loading: false,
         error: '',
         data: {},
      },
      regency: {
         loading: false,
         error: '',
         data: [],
         page: 1,
         totalPage: null,
         totalData: 0,
      },
   },
};

const hadiah = (state = initialState.hadiah, action) => {
   switch (action.type) {
      case types.GET_HADIAH_LOADING:
         return {
            ...state,
            list: {
               ...state.list,
               loading: true,
            },
         };
      case types.GET_HADIAH_SUCCESS:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.list.page,
            },
         };
      case types.GET_HADIAH_FAILED:
         return {
            ...state,
            list: {
               ...state.list,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_REDEEM_LOADING:
         return {
            ...state,
            listRedeem: {
               ...state.listRedeem,
               loading: true,
            },
         };
      case types.GET_REDEEM_SUCCESS:
         return {
            ...state,
            listRedeem: {
               ...state.listRedeem,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.listRedeem.page,
            },
         };
      case types.GET_REDEEM_FAILED:
         return {
            ...state,
            listRedeem: {
               ...state.listRedeem,
               loading: false,
               error: action.message,
            },
         };

      case types.CREATE_HADIAH_LOADING:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: true,
            },
         };
      case types.CREATE_HADIAH_SUCCESS:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
            },
         };
      case types.CREATE_HADIAH_FAILED:
         return {
            ...state,
            onCreate: {
               ...state.onCreate,
               loading: false,
               error: action.message,
            },
         };

      case types.DELIVERY_LOADING:
         return {
            ...state,
            onDelivery: {
               ...state.onDelivery,
               loading: true,
            },
         };
      case types.DELIVERY_SUCCESS:
         return {
            ...state,
            onDelivery: {
               ...state.onDelivery,
               loading: false,
            },
         };
      case types.DELIVERY_FAILED:
         return {
            ...state,
            onDelivery: {
               ...state.onDelivery,
               loading: false,
               error: action.message,
            },
         };

      case types.DELETE_HADIAH_LOADING:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: true,
            },
         };
      case types.DELETE_HADIAH_SUCCESS:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false,
            },
         };
      case types.DELETE_HADIAH_FAILED:
         return {
            ...state,
            onDelete: {
               ...state.onDelete,
               loading: false,
               error: action.message,
            },
         };

      case types.UPDATE_HADIAH_LOADING:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: true,
            },
         };
      case types.UPDATE_HADIAH_SUCCESS:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
            },
         };
      case types.UPDATE_HADIAH_FAILED:
         return {
            ...state,
            onUpdate: {
               ...state.onUpdate,
               loading: false,
               error: action.message,
            },
         };

      case types.SHOW_HADIAH_LOADING:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: true,
            },
         };
      case types.SHOW_HADIAH_SUCCESS:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               data: action.data,
            },
         };
      case types.SHOW_HADIAH_FAILED:
         return {
            ...state,
            detail: {
               ...state.detail,
               loading: false,
               error: action.message,
               data: {},
            },
         };

      case types.GET_LOTTERIES_LOADING:
         return {
            ...state,
            listLotteries: {
               ...state.listLotteries,
               loading: true,
            },
         };
      case types.GET_LOTTERIES_SUCCESS:
         return {
            ...state,
            listLotteries: {
               ...state.listLotteries,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page || state.listLotteries.page,
            },
         };
      case types.GET_LOTTERIES_FAILED:
         return {
            ...state,
            listLotteries: {
               ...state.listLotteries,
               loading: false,
               error: action.message,
            },
         };

      case types.GET_REGENCY_HADIAH_LOADING:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: true,
            },
         };
      case types.GET_REGENCY_HADIAH_SUCCESS:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               data: action.data,
               totalPage: parseInt(action.totalPage),
               totalData: parseInt(action.totalData),
               page: action.page,
            },
         };
      case types.GET_REGENCY_HADIAH_FAILED:
         return {
            ...state,
            regency: {
               ...state.regency,
               loading: false,
               error: action.message,
               data: [],
            },
         };
      default:
         return state;
   }
};

export default hadiah;
