// URL
import { CARD_LIMIT } from "./Data";
export const URL_GET_SUPPORT_TICKET = `/support-tickets?_limit=${CARD_LIMIT}%(params)s`;
export const URL_SUPPORT_TICKET = `/support-tickets`;
export const URL_DETAIL_SUPPORT_TICKET = `/support-tickets/%(id)s`;

// Types
export const types = {
   GET_SUPPORT_TICKET_LOADING: "GET_SUPPORT_TICKET_LOADING",
   GET_SUPPORT_TICKET_SUCCESS: "GET_SUPPORT_TICKET_SUCCESS",
   GET_SUPPORT_TICKET_FAILED: "GET_SUPPORT_TICKET_FAILED",

   CREATE_SUPPORT_TICKET_LOADING: "CREATE_SUPPORT_TICKET_LOADING",
   CREATE_SUPPORT_TICKET_SUCCESS: "CREATE_SUPPORT_TICKET_SUCCESS",
   CREATE_SUPPORT_TICKET_FAILED: "CREATE_SUPPORT_TICKET_FAILED",

   UPDATE_SUPPORT_TICKET_LOADING: "UPDATE_SUPPORT_TICKET_LOADING",
   UPDATE_SUPPORT_TICKET_SUCCESS: "UPDATE_SUPPORT_TICKET_SUCCESS",
   UPDATE_SUPPORT_TICKET_FAILED: "UPDATE_SUPPORT_TICKET_FAILED",

   DELETE_SUPPORT_TICKET_LOADING: "DELETE_SUPPORT_TICKET_LOADING",
   DELETE_SUPPORT_TICKET_SUCCESS: "DELETE_SUPPORT_TICKET_SUCCESS",
   DELETE_SUPPORT_TICKET_FAILED: "DELETE_SUPPORT_TICKET_FAILED",

   SHOW_SUPPORT_TICKET_LOADING: "SHOW_SUPPORT_TICKET_LOADING",
   SHOW_SUPPORT_TICKET_SUCCESS: "SHOW_SUPPORT_TICKET_SUCCESS",
   SHOW_SUPPORT_TICKET_FAILED: "SHOW_SUPPORT_TICKET_FAILED"
};
